import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';

export const UP_COLOR = '#32cd66';
export const DOWN_COLOR = '#ff6600';
export const BG_COLOR = 'rgba(50, 205, 102, 0.2)';

export const CHART_LOCALE = am4lang_ru_RU;

export const navigationTabs = [
  {
    name: 'Оперативные показатели',
    icon: '/vertical_icons/1-white.png',
    children: [
      {
        name: 'Анализ обращений граждан',
        link: '/appeals',
      },
      {
        name: 'Криминогенная обстановка',
        link: '/crime',
      },
      {
        name: 'Зарегистрированные дорожно-транспортные происшествия',
        link: '/dtp',
      },
      {
        name: 'Чрезвычайные ситуации',
        link: '/emergency-situation',
      },
    ],
  },
  {
    name: 'Религиозная обстановка',
    icon: '/vertical_icons/1_2.png',
    children: [
      {
        name: 'Подписчики интернет-сообществ',
        link: '/religion',
      },
      {
        name: 'Приверженцы ДРТ',
        link: '/drm-supporters',
      },
      {
        name: 'Информационно-разъяснительные работы',
        link: '/explanatory-work',
      },
      {
        name: 'Работа с приверженцами ДРТ в УИС',
        link: '/convicts',
      },
    ],
  },
  {
    name: 'Экономика',
    icon: '/vertical_icons/2-white.png',
    children: [
      {
        name: 'Мониторинг гос закупок',
        link: '/gos-acquisition',
      },
      {
        name: 'Земельные отношения и строительство',
      },
      {
        name: 'Туризм',
        link: '/tourism',
      },
      {
        name: 'Моделирование ВРП',
        link: '/forecast/vrp',
      },
      {
        name: 'Курсы валют',
        link: '/exchange-rates',
      },
      {
        name: 'Государственные закупки',
        link: '/procurements',
      },
    ],
  },
  {
    name: 'Социальное развитие',
    icon: '/vertical_icons/3-white.png',
    children: [
      {
        name: 'Дошкольное образование',
        link: '/social/ddo',
      },
      {
        name: 'Среднее образование',
        link: '/social/school',
      },
      {
        name: 'Продовольственная корзина',
        link: '/food-basket',
      },
      {
        name: 'Дополнительное образование',
        link: '/social/curricular-activities',
      },
      {
        name: 'Моделирование численности населения',
        link: '/forecast/population',
      },
    ],
  },
  {
    name: 'Транспорт',
    icon: '/vertical_icons/4-white.png',
    children: [
      {
        name: 'Общественый транспорт',
      },
      {
        name: 'План ремонта и строительства инфраструктурных сетей',
        link: '/transport/road-construction',
      },
      {
        name: 'Транспортные потоки',
        link: '/transport-flow',
      },
    ],
  },
  {
    name: 'Госслужба',
    icon: '/vertical_icons/5-white.png',
    children: [
      {
        name: 'Эффективность работы госслужащих',
        link: '/employees-efficiency',
      },
      {
        name: 'Государственные услуги',
        link: '/gos-services',
      },
      {
        name: 'Служебная корреспонденция',
        link: '/correspondence',
      },
    ],
  },
  {
    name: 'Видеонаблюдение',
    icon: '/vertical_icons/6-white.png',
    link: '/video-monitoring',
  },
  {
    name: 'Карта',
    icon: '/vertical_icons/7-white.png',
    link: '/map',
  },
  {
    name: 'Показатели',
    icon: '/vertical_icons/8-white.png',
    children: [
      {
        name: 'Аналитические индикаторы',
        link: '/analytical-indicators',
      },
      {
        name: 'Сравнение показателей',
      },
    ],
  },
  {
    name: 'Паспорта УТО',
    icon: '/vertical_icons/9-white.png',
    link: '/processing-dc',
  },
  {
    name: 'Энергоресурсы',
    icon: '/vertical_icons/2_2.png',
    link: '/energy-resources',
  },
  {
    name: 'Сельское хозяйство',
    icon: '/vertical_icons/4_2.png',
    link: '/agriculture',
  },
  {
    name: 'Земельные отношения',
    icon: '/vertical_icons/5_2.png',
    children: [
      {
        link: '/land-relation',
        name: 'Используемые земли',
      },
    ],
  },
  {
    name: 'Здравоохранения',
    icon: '/vertical_icons/3_2.png',
    children: [
      {
        link: '/birth-rate',
        name: 'Рождаемость',
      },
    ],
  },
];
export const COLORS = [
  '#0975da',
  '#e7941a',
  '#828282',
  '#edd10b',
  '#32b212',
  '#4eacdf',
  '#71058f',
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
  '#ff0000',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
];

export const menGroups = [
  {
    percentage: 0.17,
    multiplier: 0.95,
  },
  {
    percentage: 0.13,
    multiplier: 0.14,
  },
  {
    percentage: 0.16,
    multiplier: 0.37,
  },
  {
    percentage: 0.16,
    multiplier: 1.9,
  },
  {
    percentage: 0.13,
    multiplier: 1.42,
  },
  {
    percentage: 0.13,
    multiplier: 2.84,
  },
  {
    percentage: 0.08,
    multiplier: 5.42,
  },
  {
    percentage: 0.03,
    multiplier: 10.63,
  },
  {
    percentage: 0.01,
    multiplier: 26.64,
  },
];

export const womenGroups = [
  {
    percentage: 0.15,
    multiplier: 1.04,
  },
  {
    percentage: 0.11,
    multiplier: 0.07,
  },
  {
    percentage: 0.14,
    multiplier: 0.13,
  },
  {
    percentage: 0.14,
    multiplier: 0.4,
  },
  {
    percentage: 0.13,
    multiplier: 0.72,
  },
  {
    percentage: 0.14,
    multiplier: 1.32,
  },
  {
    percentage: 0.11,
    multiplier: 2.22,
  },
  {
    percentage: 0.06,
    multiplier: 5.22,
  },
  {
    percentage: 0.03,
    multiplier: 29.3,
  },
];

export const colorArray = [
  'rgb(45, 165, 126)',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
