import React from 'react';

const Ecology2 = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/ecology1"
      title="ecology1"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Ecology2;
