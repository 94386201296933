import React, { Component } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_amchartsdark from '@amcharts/amcharts4/themes/amchartsdark';

import { CHART_LOCALE } from '../../../components/common/Constants';
am4core.useTheme(am4themes_amchartsdark);

class VrpLineChart extends Component {
  componentDidMount() {
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.language.locale = CHART_LOCALE;
    const { dispersion, dispersionUp, dispersionDown } = this.props;
    const labels = [2018, 2019, 2020, 2021, 2022, 2023];
    let data = dispersion.map((item, index) => {
      return {
        dispersion: item,
        date: labels[index],
        dispersionUp: dispersionUp[index],
        dispersionDown: dispersionDown[index],
      };
    });
    chart.data = data;

    chart.numberFormatter.numberFormat = '#.#a';
    chart.numberFormatter.bigNumberPrefixes = [
      // { "number": 1e+3, "suffix": "тыс" },
      { number: 1e6, suffix: 'млн' },
      { number: 1e9, suffix: 'млрд' },
    ];

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'dispersion';
    series.dataFields.categoryX = 'date';
    series.name = 'ВРП';
    series.tooltipText = '{name}: [bold]{valueY}[/]';
    series.strokeWidth = 3;
    series.bullets.push(new am4charts.CircleBullet());

    let disUp = chart.series.push(new am4charts.LineSeries());
    disUp.dataFields.categoryX = 'date';
    disUp.dataFields.valueY = 'dispersionUp';
    disUp.name = 'Прогнозный интервал (Оптимистичный сценарий)';
    disUp.tooltipText = '{name}: [bold]{valueY}[/]';
    disUp.strokeWidth = 3;
    disUp.bullets.push(new am4charts.CircleBullet());

    let disDown = chart.series.push(new am4charts.LineSeries());
    disDown.dataFields.categoryX = 'date';
    disDown.dataFields.valueY = 'dispersionDown';
    disDown.name = 'Прогнозный интервал (Пессимистичный сценарий)';
    disDown.tooltipText = '{name}: [bold]{valueY}[/]';
    disDown.strokeWidth = 3;
    disDown.bullets.push(new am4charts.CircleBullet());

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.itemContainers.template.marginBottom = 20;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id={this.props.id} style={{ width: '100%', height: '500px' }} />;
  }
}
export default VrpLineChart;
