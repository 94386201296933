import React, { Component } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import { FaArchway, FaLandmark, FaPhoneAlt, FaRoad, FaSchool, FaTree } from 'react-icons/fa';
import moment from 'moment';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { CAMERAS, STATS } from './EddsData';

import cubejs from '../../cube';
import request from '../../utils/request';

import styles from './edds.module.scss';
const cubejsApi = cubejs({ appId: 1 });

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0]) {
    return (
      <div
        style={{
          width: '180px',
          height: '25px',
          color: 'black',
          position: 'relative',
          fontWeight: 'bold',
          textAlign: 'center',
          borderRadius: '2px',
          border: '1px solid white',
          backgroundColor: '#1890ff',
        }}
        className="custom-tooltip"
      >
        <p className="label">{`${payload[0].name}:${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
class Edds extends Component {
  state = {
    range: [moment().utc(6).startOf('day'), moment().utc(6).endOf('day')],
    rangeYear: [
      moment().startOf('year').format('YYYY/MM/DD'),
      moment().endOf('year').format('YYYY/MM/DD'),
    ],
    emergency: {},
    emergencyByYear: {},
    total: 0,
    totalByYear: 0,
    pieData: [],
    loading: true,
    dataByTime: [],
  };

  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }

  getData = async () => {
    this.setState({ loading: true });
    const query = {
      measures: ['Appeals.appealsNum'],
      timeDimensions: [],
      filters: [],
      renewQuery: true,
    };

    const queryYear = {
      measures: ['Appeals.appealsNum'],
      timeDimensions: [],
      filters: [],
      renewQuery: true,
    };

    const queryDate = {
      timeDimensions: [],
      dimensions: ['Appeals.submissionDate'],
      filters: [],
      renewQuery: true,
    };

    if (this.state.range) {
      query.filters.push({
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: this.state.range,
      });
      queryDate.filters.push({
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: this.state.range,
      });
    }

    if (this.state.rangeYear) {
      queryYear.filters.push({
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: this.state.rangeYear,
      });
    }

    const resultSet = await cubejsApi.load(query);
    const resultSetDate = await cubejsApi.load(queryDate);
    const resultSetYear = await cubejsApi.load(queryYear);

    request('/ccadmin/api/v1/emergency/inserter/get', {
      method: 'GET',
    }).then((r) => {
      let emergencyByService = r
        .filter((e) => moment(e.date).format('YYYY-MM-DD') === moment().utc(6).format('YYYY-MM-DD'))
        .map((e) => ({ emergencyService: e.emergencyService, date: e.date }));

      let emergencyByServiceByYear = r
        .filter((e) => moment(e.date).year() === moment().year())
        .map((e) => ({ emergencyService: e.emergencyService, date: e.date }));

      resultSetDate.rawData().forEach((e) => {
        emergencyByService.push({
          emergencyService: 'Appeals',
          date: e['Appeals.submissionDate'],
        });
      });

      let emergencyCount = emergencyByService.filter(
        (e) => e.emergencyService === 'Emergency'
      ).length;
      let ambulanceCount = emergencyByService.filter(
        (e) => e.emergencyService === 'Ambulance'
      ).length;
      let fireServiceCount = emergencyByService.filter(
        (e) => e.emergencyService === 'FireService'
      ).length;
      let policeCount = emergencyByService.filter((e) => e.emergencyService === 'Police').length;
      let appealsCount = parseInt(resultSet.rawData()[0]['Appeals.appealsNum']);

      let emergencyCountByYear =
        emergencyByServiceByYear.filter((e) => e.emergencyService === 'Emergency').length + 173264;
      let ambulanceCountByYear =
        emergencyByServiceByYear.filter((e) => e.emergencyService === 'Ambulance').length + 505920;
      let fireServiceCountByYear =
        emergencyByServiceByYear.filter((e) => e.emergencyService === 'FireService').length +
        169536;
      let policeCountByYear =
        emergencyByServiceByYear.filter((e) => e.emergencyService === 'Police').length + 341632;
      let appealsCountByYear = parseInt(resultSetYear.rawData()[0]['Appeals.appealsNum']);

      let pieDataByService = [];
      pieDataByService.push({
        emergencyCategory: 'Обращения граждан',
        count: appealsCount,
      });
      pieDataByService.push({
        emergencyCategory: 'Скорая помощь',
        count: ambulanceCount,
      });
      pieDataByService.push({
        emergencyCategory: 'Пожарная служба',
        count: fireServiceCount,
      });
      pieDataByService.push({
        emergencyCategory: 'Полиция',
        count: policeCount,
      });
      pieDataByService.push({
        emergencyCategory: 'ЧС',
        count: emergencyCount,
      });

      let dataByTime = [];
      for (let index = 0; index < 24; index++) {
        let indexStr = index < 10 ? '0' + index : index.toString();
        dataByTime.push({
          index,
          value: emergencyByService.filter((el) => moment(el.date).format('HH') === indexStr)
            .length,
          hour: `${indexStr}:00`,
        });
      }

      this.setState({
        dataByTime,
        emergencyByYear: {
          emergency: emergencyCountByYear.toLocaleString(),
          ambulance: ambulanceCountByYear.toLocaleString(),
          fireService: fireServiceCountByYear.toLocaleString(),
          police: policeCountByYear.toLocaleString(),
          appeals: appealsCountByYear.toLocaleString(),
        },
        emergency: {
          emergency: emergencyCount,
          ambulance: ambulanceCount,
          fireService: fireServiceCount,
          police: policeCount,
          appeals: appealsCount,
        },
        total: emergencyCount + ambulanceCount + policeCount + appealsCount + fireServiceCount,
        totalByYear: (
          emergencyCountByYear +
          ambulanceCountByYear +
          policeCountByYear +
          appealsCountByYear +
          fireServiceCountByYear
        ).toLocaleString(),
        pieData: pieDataByService,
        loading: false,
      });
    });
  };

  getIcon = (val) => {
    switch (val) {
      case 'Дороги':
        return <FaRoad />;
      case 'Государственные учреждения':
        return <FaLandmark />;
      case 'Организации образования':
        return <FaSchool />;
      case 'Общественные места':
        return <FaArchway />;
      case 'Дворы':
        return <FaTree />;
      default:
        return val;
    }
  };

  render() {
    const { emergency, emergencyByYear, total, totalByYear, dataByTime } = this.state;
    const statsByDep = STATS.map((el) => ({
      ...el,
      count: emergency[el.key],
      countYear: emergencyByYear[el.key],
    }));
    return (
      <div className={styles.wrap}>
        <div className={styles.totalCalls}>
          <div className={styles.icon}>
            <FaPhoneAlt />
          </div>
          <div className={styles.content}>
            <h4 className={styles.title}>Всего звонков за текущий год:</h4>
            <h2 className={styles.stat}>{totalByYear}</h2>
          </div>
          <div className={styles.content}>
            <h4 className={styles.title}>Всего звонков сегодня:</h4>
            <h2 className={styles.stat}>{total}</h2>
          </div>
        </div>
        <div className={styles.stats}>
          {statsByDep.map((el) => (
            <div className={styles.statsItem} key={el.title}>
              <div className={styles.icon}>
                <img src={el.icon} alt={el.title} />
              </div>
              <h2 className={styles.title}>{el.title}</h2>

              <div className={styles.smallStats}>
                <div>
                  <p>Текущий год</p>
                  <h4>{el.countYear}</h4>
                </div>
                <div>
                  <p>Сегодня</p>
                  <h4>{el.count}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.chartsWrap}>
          <div className={styles.pieWrap}>
            <h2 className={styles.titleBig}>Звонков по службам:</h2>
            <div className={styles.legends}>
              {statsByDep.map((el) => (
                <div className={styles.legendItem} key={el.key}>
                  <span style={{ backgroundColor: el.color }}></span>
                  <h4>{el.title}</h4>
                  <h5 style={{ width: '21%', textAlign: 'right' }}>
                    {el.count} - {Math.round((el.count / total) * 100)}%
                  </h5>
                </div>
              ))}
            </div>
            <div style={{ width: '50%' }}>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width={300} height={200}>
                  <Pie
                    dataKey="count"
                    data={statsByDep}
                    cx={250}
                    cy={150}
                    innerRadius={75}
                    outerRadius={120}
                    fill="#82ca9d"
                    stroke="none"
                  >
                    <Tooltip />
                    {statsByDep.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                    <Label
                      value={total}
                      fill="white"
                      position="center"
                      style={{
                        fontSize: '36px',
                        lineHeight: '1.5',
                        fontWeight: 'bold',
                      }}
                    />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={styles.barWrap}>
            <h2 className={styles.titleBig}>Звонков по времени:</h2>
            <ResponsiveContainer width="95%" height={300}>
              <BarChart
                height={200}
                data={dataByTime}
                margin={{
                  top: 30,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="hour" tick={{ fill: 'white' }} />
                <YAxis tick={{ fill: 'white' }} />
                <Tooltip content={<CustomTooltip />} />

                <Bar dataKey="value" name="Количество звонков" fill="#1890ff" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={styles.totalCalls}>
          <div className={styles.icon}>
            <BsCameraVideo />
          </div>
          <div className={styles.content}>
            <h4 className={styles.title}>Всего камер наблюдения в области</h4>
            <h2 className={styles.stat}>7631</h2>
          </div>
        </div>
        <div className={styles.cameraStats}>
          {CAMERAS.map((cam) => (
            <div key={cam.city} className={styles.cameraItem}>
              <div className={styles.cameraItemWrap}>
                <div>
                  <h4 className={styles.title}>{cam.city}</h4>
                  <h2 className={styles.stat}>{cam.items.reduce((a, b) => a + b.value, 0)}</h2>
                </div>
                <div className={styles.icon}>
                  <BsCameraVideo />
                </div>
              </div>
              <div className={styles.cameraItemCategories}>
                {cam.items.map((cat) => (
                  <div key={cat.title} className={styles.catItem}>
                    {this.getIcon(cat.title)}
                    <p>{cat.title}</p>
                    <span>{cat.value}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default Edds;
