import React from 'react';

const ClinicVisitors = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/clinic-visitors"
      title="clinic-visitors"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};
export default ClinicVisitors;
