import React from 'react';

const HigherEducation = () => {
  return (
    <iframe
      src="https://bicloud.krgsc.kz/public/dashboards/34"
      title="Higher Education"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default HigherEducation;
