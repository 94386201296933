import _ from 'lodash';

export const getSingleValue = (resultSet, key) => {
  if (!resultSet) return;
  const data = resultSet.rawData()[0];
  return data[key];
};

export const generatePieData = (resultSet) => {
  if (!resultSet) return;
  const query = resultSet.query();
  const data = resultSet.rawData();
  const seriesNames = resultSet.seriesNames();
  const { measures, dimensions } = query;

  if (measures.length === 1 && dimensions.length === 1) {
    return {
      data,
      nameKey: dimensions[0],
      dataKey: measures[0],
    };
  }
  if (measures.length && dimensions.length === 0) {
    return {
      data: seriesNames.map((s) => {
        const divide = s.title ? s.title.split(':') : [''];
        return {
          category: divide.length === 2 ? divide[1].trim() : divide[0],
          value: data[0][s.key],
        };
      }),
    };
  }
};

export const generateLineData = (resultSet) => {
  if (!resultSet) return;
  const query = resultSet.query();
  const data = resultSet.rawData();
  const seriesNames = resultSet.seriesNames();
  const { measures, dimensions, timeDimensions } = query;

  if (measures.length && dimensions.length === 1 && timeDimensions.length === 0) {
    return {
      data,
      category: dimensions[0],
      values: seriesNames,
    };
  }
  if (measures.length && dimensions.length === 0 && timeDimensions.length === 1) {
    return {
      data,
      category: timeDimensions[0].dimension,
      values: seriesNames,
    };
  }
  if (measures.length === 1 && dimensions.length === 2) {
    const categories = _.uniq(data.map((d) => d[dimensions[1]]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(':') : [''];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [dimensions[1]]: c };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[dimensions[1]] === c);
        obj = {
          ...obj,
          // [s]: grouped[s][i][measures[0]]
          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    // console.log(normalized, dimensions[1], seriesNames);

    return {
      data: normalized,
      category: dimensions[1],
      values: seriesNames,
    };
  }

  if (measures.length === 1 && dimensions.length === 1 && timeDimensions.length === 1) {
    const category = timeDimensions[0].dimension;
    const categories = _.uniq(data.map((d) => d[category]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(':') : [''];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [category]: c };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[category] === c);
        obj = {
          ...obj,
          // [s]: grouped[s][i][measures[0]]
          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    return {
      data: normalized,
      category,
      values: seriesNames,
    };
  }
};

export const generateBarData = (resultSet) => {
  if (!resultSet) return;
  const query = resultSet.query();
  const data = resultSet.rawData();
  const seriesNames = resultSet.seriesNames();

  const { measures, dimensions, timeDimensions } = query;

  if (measures.length && dimensions.length === 1 && timeDimensions.length === 0) {
    return {
      data,
      category: dimensions[0],
      values: seriesNames.map((it) => ({
        ...it,
        title: it.title ? it.title.split(':')[1] : '',
      })),
    };
  }
  if (measures.length && dimensions.length === 0 && timeDimensions.length === 1) {
    return {
      data,
      category: timeDimensions[0].dimension,
      values: seriesNames,
    };
  }
  if (measures.length === 1 && dimensions.length === 2) {
    const categories = _.uniq(data.map((d) => d[dimensions[1]]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(':') : [''];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [dimensions[1]]: !!c && c !== '' ? c : 'Не определено' };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[dimensions[1]] === c);
        obj = {
          ...obj,
          // [s]: grouped[s][i][measures[0]]
          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    return {
      data: normalized,
      category: dimensions[1],
      values: seriesNames,
    };
  }

  if (measures.length === 1 && dimensions.length === 1 && timeDimensions.length === 1) {
    const category = timeDimensions[0].dimension;
    const categories = _.uniq(data.map((d) => d[category]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(':') : [''];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [category]: c };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[category] === c);
        obj = {
          ...obj,
          // [s]: grouped[s][i][measures[0]]
          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    return {
      data: normalized,
      category,
      values: seriesNames,
    };
  }
};
