import React, { useState } from 'react';

import ArmListContainer from './ArmListContainer';
import { ArmProvider } from './context';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';
import AnalyticalIndicators from '../AnalyticalIndicators';

const NAME_COMP = {
  'Аналитические индикаторы': <ArmListContainer />,
  Показатели: <AnalyticalIndicators />,
};

function Arm() {
  const [radio, setRadio] = useState(Object.keys(NAME_COMP)[0]);

  const radioChange = (value) => {
    setRadio(value.target.value);
  };

  return (
    <MainWrapper>
      <CaseMainTitle>Аналитические индикаторы</CaseMainTitle>

      <RadioGroup
        onChange={radioChange}
        value={radio}
        list={Object.keys(NAME_COMP).map((key) => ({
          key: key,
          value: key,
        }))}
      />
      {Object.keys(NAME_COMP).map((nav) => {
        if (nav === radio) return NAME_COMP[nav];
        else return null;
      })}
    </MainWrapper>
  );
}

const Wrapper = () => (
  <ArmProvider>
    <Arm />
  </ArmProvider>
);

export default Wrapper;
