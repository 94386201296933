import React from 'react';
import { Button, Row } from 'antd';

import { EcologyReportProvider, useEcologyReport } from './context';
import EcologyMap from './EcologyMap';
import EcologyReportTable from './EcologyReportTable';
import Filters from './Filters';

import LoadingSpinner from '../../components/LoadingSpinner';

function EcologyReport() {
  const { loading, handleReportDataFetch, handleFilterReset } = useEcologyReport();
  return (
    <div className="p-10">
      <LoadingSpinner loading={loading}>
        <div className="h-100" style={{ marginTop: 20 }}>
          <Filters />
          <Row justify="end" flex style={{ marginTop: 20 }}>
            <Button onClick={handleReportDataFetch}>Сохранить</Button>
            <Button onClick={handleFilterReset} style={{ marginLeft: 15 }}>
              Очистить
            </Button>
          </Row>
          <div style={{ width: '100%', marginTop: 20, height: 600 }}>
            <EcologyMap />
          </div>
          <div style={{ display: 'flex', marginTop: 30 }}>
            <div style={{ display: 'flex', marginRight: 15 }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  backgroundColor: '#2BA6FF',
                  marginRight: 10,
                }}
              ></div>
              <div>Низкий уровень загрязнений</div>
            </div>
            <div style={{ display: 'flex', marginRight: 15 }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  backgroundColor: '#FFC224',
                  marginRight: 10,
                }}
              ></div>
              <div>Повышенный уровень загрязнений</div>
            </div>
            <div style={{ display: 'flex', marginRight: 15 }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  backgroundColor: '#FF7324',
                  marginRight: 10,
                }}
              ></div>
              <div>Высокий уровень загрязнений</div>
            </div>
          </div>
          <EcologyReportTable />
        </div>
      </LoadingSpinner>
    </div>
  );
}

const Wrapper = () => (
  <EcologyReportProvider>
    <EcologyReport />
  </EcologyReportProvider>
);

export default Wrapper;
