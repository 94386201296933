import request from '../utils/request';

export function login({ username, password }) {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  return request('/api/login', {
    method: 'POST',
    body: params,
  });
}

// export function ccadminLogin({ username, password }) {
//   const params = new URLSearchParams();
//   params.append("username", username);
//   params.append("password", password);
//   return request("/ccadmin/login", {
//     method: "POST",
//     body: params
//   });
// }

export function logout() {
  return request('/api/logout');
}

// export function ccadminLogout() {
//   return request("/ccadmin/logout");
// }
