import React from 'react';

const SecondaryEducation = () => {
  return (
    <iframe
      src="https://bicloud.krgsc.kz/public/dashboards/49"
      title="secondary education"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default SecondaryEducation;
