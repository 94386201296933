import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

import rootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [ReduxThunk];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
