import React from 'react';

const url = `https://app.powerbi.com/view
?r=eyJrIjoiZWU5OWU0NzgtOTRiYS00NjgwLTk4ZmUtY2I4OWI1YjYzNTAwIiwidCI6ImZkYTc1ZGJiLTc5MjUtNGM5Ny1hM2NhLTNiZDJmZDU0OWU5MCIsImMiOjl9`;

const CallControlIframe = () => (
  <iframe src={url} style={{ border: 'none' }} width="100%" height="100%" />
);

export default CallControlIframe;
