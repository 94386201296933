import React, { Component } from 'react';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: 'a',
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    return (
      <div className="p-10 h-100">
        {/* <Radio.Group
          defaultValue="a"
          buttonStyle="solid"
          onChange={this.radioChange}
          className="mb-10"
        >
          <Radio.Button value="a">Статистика</Radio.Button>
          <Radio.Button value="b">Оперативная сводка</Radio.Button>
        </Radio.Group>
        {this.state.radio === 'a' && ( */}
        {/* <iframe
          src="https://iframe.krgsc.kz/statistics"
          title="statistics"
          style={{ border: 'none' }}
          width="100%"
          height="100%"
        /> */}
        {/* )}
        {this.state.radio === 'b' && ( */}
        {/* <> */}
        <iframe
          src="https://iframe.krgsc.kz"
          title="infopanel"
          style={{ border: 'none' }}
          width="100%"
          height="105%"
        />
        {/* </> */}
        {/* )} */}
      </div>
    );
  }
}
