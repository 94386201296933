import React from 'react';
import { DatePicker } from 'antd';

import styles from './DatePickerR.module.scss';

export function DatePickerR({ label = '', ...componentProps }) {
  return (
    <div className={styles.date_picker_wrapper}>
      <div className={styles.date_picker_title}>{label}</div>
      <DatePicker.RangePicker className={styles.date_picker_component} {...componentProps} />
    </div>
  );
}
