import React from 'react';

import LineGraph from './LineGraph';

import { ChartCard } from '../../components/newDesign/ChartCard/ChartCard';

const Indicator = ({ indicator, id }) => {
  const uniqLabel = Array.from(
    new Set([...indicator.fact.data, ...indicator.plan.data].map((e) => e.label))
  );
  const data = uniqLabel.map((label) => {
    const plan = indicator.plan.data.find((e) => e.label === label);
    const fact = indicator.fact.data.find((e) => e.label === label);
    return {
      year: label + '',
      plan: plan ? plan.value : null,
      fact: fact ? fact.value : null,
    };
  });
  return (
    <ChartCard style={{ flex: 'unset', width: '45%' }} title={indicator.title}>
      <LineGraph data={data} id={id} unit={indicator.unitType} />
    </ChartCard>
  );
};

export default Indicator;
