import React from 'react';

const AnalyticaTelephonyi = () => {
  return (
    <iframe
      src="https://bicloud.krgsc.kz/public/dashboards/45"
      title="analitica telefonyi"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default AnalyticaTelephonyi;
