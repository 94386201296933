import React, { useState } from 'react';
import { Button, Modal, Row, Table } from 'antd';
import moment from 'moment';

import { ChartCard } from '../../../../../components/newDesign/ChartCard';
import { PAYMENT_STATUS } from '../../../../../constants';

import styles from './RoadRepairTable.module.scss';

const pagination = {
  defaultPageSize: 10,
  pageSizeOptions: ['10', '20', '50', '100'],
  showSizeChanger: true,
};

const RoadRepairTable = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [choosedContract, setChoosedContract] = useState();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rowClicked = (value) => {
    setChoosedContract({
      ...value.mgzRoadwayDTO,
      ...value.mgzProcessedRoadwayDTO,
    });
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: '№ договора № п/п',
      dataIndex: 'contractPlanNumber',
      fixed: 'left',
      width: 130,
      // defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a?.mgzProcessedRoadwayDTO?.planNumber - b?.mgzProcessedRoadwayDTO?.planNumber,
      render: (_, record) => (
        <p>
          {record.mgzRoadwayDTO?.contractNumber} <br />
          <br />
          {record.mgzProcessedRoadwayDTO?.planNumber}
        </p>
      ),
    },
    {
      title: 'Дата подписания',
      dataIndex: 'mgzRoadwayDTO.signDate',
      key: 'signDate',
      sorter: (a, b) =>
        moment(a.mgzRoadwayDTO?.signDate).unix() - moment(b.mgzRoadwayDTO?.signDate).unix(),
      width: 150,
      render: (_, record) => moment(record.mgzRoadwayDTO?.signDate).format('YYYY-MM-DD HH:MM'),
    },
    {
      title: 'Участок',
      dataIndex: 'mgzProcessedRoadwayDTO.area',
      key: 'area',
      width: 140,
      sorter: (a, b) => a.mgzProcessedRoadwayDTO?.area - b.mgzProcessedRoadwayDTO?.area,
    },
    {
      title: 'Протяженность участка (м)',
      dataIndex: 'mgzProcessedRoadwayDTO.length',
      key: 'length',
      width: 160,
      sorter: (a, b) => a.mgzProcessedRoadwayDTO?.length - b.mgzProcessedRoadwayDTO?.length,
    },

    {
      title: 'Площадь ремонта (м²)',
      dataIndex: 'mgzProcessedRoadwayDTO.square',
      key: 'square',
      width: 140,
      sorter: (a, b) => a.mgzProcessedRoadwayDTO?.square - b.mgzProcessedRoadwayDTO?.square,
    },

    {
      title: 'Категория дороги',
      dataIndex: 'roadCategory',
      key: 'roadCategory',
      width: 140,
      sorter: (a, b) => a?.roadCategory?.localeCompare(b.roadCategory),
    },
    {
      title: 'Категория ремонта',
      dataIndex: 'workCategory',
      key: 'workCategory',
      width: 140,
      sorter: (a, b) => a?.workCategory?.localeCompare(b.workCategory),
    },
    {
      title: 'Срок выполнения ремонта',
      dataIndex: 'startEndDate',
      key: 'startEndDate',
      width: 200,
      render: (_, record) => (
        <p>
          {record.mgzProcessedRoadwayDTO?.startDate}
          {' - ' + record.mgzProcessedRoadwayDTO?.endDate}
        </p>
      ),
      sorter: (a, b) =>
        moment(a.mgzProcessedRoadwayDTO?.startDate, 'YYYY-MM-DD').unix() -
        moment(b.mgzProcessedRoadwayDTO?.startDate, 'YYYY-MM-DD').unix(),
    },
    {
      title: 'Статус',
      dataIndex: 'mgzRoadwayDTO.status',
      key: 'status',
      width: 140,
      sorter: (a, b) => a?.mgzRoadwayDTO?.status?.localeCompare(b.mgzRoadwayDTO?.status),
    },
    {
      title: 'Сумма по предмету договора (тг с НДС)',
      dataIndex: 'mgzRoadwayDTO.amount',
      key: 'amount',
      width: 200,
      render: (_, record) => record.mgzRoadwayDTO?.amount?.toLocaleString(),
      sorter: (a, b) => a.mgzRoadwayDTO?.amount - b.mgzRoadwayDTO?.amount,
    },
    {
      title: 'Результат экспертизы',
      dataIndex: 'result',
      key: 'result',
      width: 200,
      sorter: (a, b) => a?.result?.localeCompare(b?.result),
    },
    {
      title: 'Гарантийный срок',
      dataIndex: 'mgzProcessedRoadwayDTO.warrantyEndDate',
      key: 'warrantyEndDate',
      width: 140,
      sorter: (a, b) =>
        moment(a.mgzProcessedRoadwayDTO?.warrantyEndDate, 'YYYY-MM-DD').unix() -
        moment(b.mgzProcessedRoadwayDTO?.warrantyEndDate, 'YYYY-MM-DD').unix(),
    },
    {
      fixed: 'right',
      width: 140,
      title: 'Ссылка на закупку',
      align: 'center',
      dataIndex: 'link',
      key: 'link',

      render: (_, record) =>
        props.contracts.length >= 1 ? (
          <a
            style={{ margin: '0 auto' }}
            href={`https://www.goszakup.gov.kz/ru/registry/contract?filter[number]=${record.mgzRoadwayDTO?.contractNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="primary">Договор</Button>
          </a>
        ) : null,
    },
  ];

  return (
    <Row>
      <Table
        className={styles.table}
        columns={columns}
        onRow={(record) => ({ onClick: () => rowClicked(record) })}
        dataSource={props.contracts.map((item, index) => ({ ...item, key: index + 1 }))}
        bordered
        pagination={props.contracts.length > 10 && pagination}
        size="small"
        scroll={{ x: 1000, y: 700 }}
      />
      <Modal width={1000} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        {choosedContract && (
          <div style={{ display: 'flex', lineHeight: 2, color: '#fff' }}>
            <p style={{ flex: 1, marginRight: 30 }}>
              <b>Договор (№ и дата):</b> {choosedContract?.contract}
              <br /> <b>№ п/п:</b> {choosedContract?.planNumber}
              <br />
              <b>Заказчик:</b> {choosedContract?.customer}
              <br /> <b>БИН заказчика:</b> {choosedContract?.customerBin} <br />
              <b>Подрядчик:</b> {choosedContract?.supplier}
              <br />
              <b>БИН/ИИН подрядчика:</b> {choosedContract?.supplierBin}
              <br />
              <b>Размерность предприятия (КРП):</b> {choosedContract?.krpCode}
              <br />
              <b>Квазигосударственное юр.лицо:</b> {choosedContract?.qvazi ? 'Да' : 'Нет'}
              <br />
              <b>Национальное юр. лицо:</b> {choosedContract?.markNational ? 'Да' : 'Нет'} <br />
              <b>Резиденство:</b> {choosedContract?.countryCode}
              <br />
              <b>Финансовый год:</b> {choosedContract?.finYear} <br />
              <b>Мероприятие:</b> {choosedContract?.description}
              <br />
              <b>Место поставки:</b> {choosedContract?.supplyPlace}
              <br />
              <b>Планируемая дата исполнения:</b> {choosedContract?.planExecDate}
              <br />
              <b>Фактический способ закупки:</b> {choosedContract?.factTradeMethod}
              <br />
              <b>Обоснование способа закупа:</b> {choosedContract?.justification}
              <br />
              <b>Общая сумма по предмету договора (тг с НДС):</b>{' '}
              {choosedContract?.amount?.toLocaleString()}
              <br />
              <b> % местного содержания:</b> {choosedContract?.ksProc}
              <br />
              <b> Статус оплаты:</b> {PAYMENT_STATUS[choosedContract?.payedPrcnt]}
              <br />
            </p>
            <div style={{ flex: 1 }}>
              <div>
                <b>Улица:</b> {choosedContract?.street[0]?.name}
              </div>
              <div>
                <b>Участок:</b> {choosedContract?.area}
              </div>
              <div>
                <b>Категория дороги:</b> {choosedContract?.roadCategory[0]?.category}
              </div>
              <div>
                <b>Категория ремонта:</b> {choosedContract?.workCategory[0]?.category}
              </div>
              <div>
                <b>Протяженность участка (м):</b> {choosedContract?.length}
              </div>
              <div>
                <b>Ширина участка (м):</b> {choosedContract?.width}
              </div>
              <div>
                <b>Площадь участка (м2):</b> {choosedContract?.square}
              </div>
              <div>
                <b>Количество ремонтируемых полос:</b> {choosedContract?.quantity}
              </div>
              <div>
                <b>Перекрытие дороги:</b> {choosedContract?.roadBlockCategory}
              </div>
              <div>
                <b>Срок выполнения ремонта:</b>{' '}
                {moment(choosedContract?.startDate).format('YYYY-MM-DD') +
                  ' - ' +
                  moment(choosedContract?.endDate).format('YYYY-MM-DD')}
              </div>
              <div>
                <b>Срок окончания гарантийного периода:</b> {choosedContract?.warrantyEndDate}
              </div>
              <div>
                <b>Комментарий по ремонту:</b> {choosedContract?.comment}
              </div>
              <div>
                <b>Дата заполнения формы ввода ремонта:</b>{' '}
                {moment(choosedContract?.createDate).format('YYYY-MM-DD')}
              </div>
              <div>
                <b>Результат экспертизы:</b>{' '}
                {choosedContract?.mgzRoadwayExpertiseDTO?.result?.result}
              </div>
              <div>
                <b>Номер заключения:</b> {choosedContract?.mgzRoadwayExpertiseDTO?.number}
              </div>
              <div>
                <b>Дата заключения:</b>{' '}
                {moment(choosedContract?.mgzRoadwayExpertiseDTO?.dateOfConclusion).format(
                  'YYYY-MM-DD'
                )}
              </div>
              <div>
                <b>Организация проводившая экспертизу:</b>{' '}
                {choosedContract?.mgzRoadwayExpertiseDTO?.organization}
              </div>
              <div>
                <b>Комментарий по результату экспертизы:</b>{' '}
                {choosedContract?.mgzRoadwayExpertiseDTO?.comment}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Row>
  );
};

export default RoadRepairTable;
