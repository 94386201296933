import moment from 'moment';

export function isJson(string) {
  try {
    JSON.parse(string);
  } catch (err) {
    return false;
  }
  return true;
}

export const formatLongNumber = (value, to = null) => {
  if (!value) return 0;

  if (value <= 999) {
    // hundreds
    return value.toFixed(1);
  } else if (value >= 1000 && value <= 999999) {
    // thousands
    return (value / 1000).toFixed(to) + 'млн';
  } else if (value >= 1000000 && value <= 999999999) {
    // millions
    return (value / 1000000).toFixed(to) + 'млрд';
  } else if (value >= 1000000000 && value <= 999999999999) {
    // billions
    return (value / 1000000000).toFixed(to) + 'млрд';
  } else {
    return value.toFixed(to);
  }
};

export const numberWithCommas = (x, delimitter = ',') => {
  return (Math.round(x * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimitter);
};

export const range = (min, max, step = 1) => {
  const arr = [];
  const totalSteps = Math.floor((max - min) / step);
  for (let ii = 0; ii <= totalSteps; ii++) {
    arr.push(ii * step + min);
  }
  return arr;
};

export const formatDate = (date, format = 'DD.MM.YYYY') => {
  return moment(date).format(format);
};

export const formatDateTime = (date) => {
  return moment(date).format('DD.MM.YYYY HH:mm:ss');
};

export const minutesOfDay = function (t) {
  return t.m + t.h * 60;
};

export const secondsOfDay = function (t) {
  return t.s + t.m * 60 + t.h * 3600;
};

export const generateTimePeriods = () => {
  const x = 30; //minutes interval
  const times = []; // time array
  let tt = 0; // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2) + ':00';
    tt = tt + x;
  }
  return times;
};
