import React from 'react';

const Dtp = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/dtp"
      title="dtp"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Dtp;
