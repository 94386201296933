import React, { Component } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import * as am4plugins_sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper';
import cubejs from '@cubejs-client/core';
import { message, Modal, Row } from 'antd';
import PropTypes from 'prop-types';

import { numberWithCommas } from '../../utils/helpers';
import ExportExcel from '../ExportExcel';

const COLORS = [
  '#FF4D4F',
  '#FADB14',
  '#4B9A25',
  '#1870DF',
  '#A05ACC',
  '#F759AB',
  '#FF99CC',
  '#A31824',
  '#0FABBC',
  '#E76D83',
  '#55CEA5',
  '#F0B132',
  '#57B4E9',
  '#CD66CC',
  '#FFA07A',
  '#C23B22',
  '#F8DE7E',
  '#2EB872',
  '#CE7EAE',
  '#4682B4',
  '#FF6347',
  '#BDB76B',
  '#DDA0DD',
  '#2F4F4F',
  '#556B2F',
  '#BC8F8F',
  '#6495ED',
  '#FFA500',
  '#696969',
];

const cubejsApi = cubejs({ appId: 1 });

export default class PieChart extends Component {
  state = { visible: false, items: [] };

  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { loading, id } = this.props;

    // Create chart instance
    const chart = am4core.create(id, am4charts.PieChart);
    chart.language.locale = am4lang_ru_RU;

    this.chart = chart;
    if (loading) {
      this.showIndicator();
    } else {
      this.initChart();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    if (loading) {
      this.showIndicator();
    } else {
      this.initChart();
    }
  }
  initChart = () => {
    am4core.options.commercialLicense = true;
    const {
      data,
      nameKey,
      dataKey,
      query,
      colorList,
      showLegend,
      showLabels,
      innerRadius,
      legendPosition,
      showPercent,
      handleClick,
      hideSmallValues,
      disableMovement,
      legendCopyable,
    } = this.props;
    if (!data) return;

    // Add data
    this.chart.data = data.length ? data : [];

    // Create pie series
    this.chart.series.clear();
    let series = this.chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = dataKey;
    series.dataFields.category = nameKey;
    // series.labels.template.text = "{category}: {value.value}";
    // series.slices.template.tooltipText = "{category}: {value.value}";
    series.slices.template.propertyFields.isActive = 'pulled';
    if (this.props.customTitle) {
      series.name = this.props.customTitle;
    }

    // adding plugin for grouping small values
    if (hideSmallValues) {
      let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
      grouper.threshold = 1;
      grouper.groupName = 'Другие';
      // grouper.clickBehavior = "break";
      // grouper.groupProperties.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      grouper.groupProperties.fill = '#D8D8D8';
      grouper.groupProperties.stroke = '#D8D8D8';

      // make only 1 slice active
      series.slices.template.events.on('hit', (evt) => {
        let slice = evt.target;
        series.slices.each(function (item) {
          if (item !== slice) item.isActive = false;
        });
        slice.isActive = true;
      });
    }

    if (disableMovement) {
      let slice = series.slices.template;
      slice.states.getKey('hover').properties.scale = 1;
      slice.states.getKey('active').properties.shiftRadius = 0;
    }

    // click
    if (handleClick) {
      series.slices.template.events.on('hit', (ev) => {
        const { category } = ev.target.dataItem;
        if (Object.keys(query).length) this.loadDetails(category);
        else handleClick(category);
      });
    }

    this.chart.innerRadius = am4core.percent(innerRadius);
    // Tooltip
    if (this.props.tooltip) {
      series.slices.template.tooltipText = this.props.tooltip;
    }
    series.slices.template.alwaysShowTooltip = this.props.alwaysShowTooltip;

    if (this.props.data && this.props.showTotal && this.props.data[0]) {
      let totalNumber = this.props.data.reduce((a, b) => a + Number(b[dataKey]), 0);
      let total = series.createChild(am4core.Label);
      total.text = this.props.totalValue
        ? this.props.totalValue
        : numberWithCommas(totalNumber, ' ');
      total.fontSize = this.props.totalFontSize || 12;
      total.fontWeight = 'bold';
      total.horizontalCenter = 'middle';
      total.verticalCenter = 'middle';
    }

    // legend
    if (showLegend) {
      if (this.chart.legend) this.chart.legend.dispose();
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.fontSize = 12;
      this.chart.legend.position = legendPosition;
      this.chart.legend.layout = 'grid';
      this.chart.legend.maxColumns = 4;

      if (!showPercent) {
        this.chart.legend.valueLabels.template.text = null; // remove percentage
      }
      this.chart.legend.labels.template.text =
        "{name}: [/] [bold]{value} — [bold]{value.percent.formatNumber('#.00')}%";
      this.chart.legend.labels.template.adapter.add('text', function (labelText, target) {
        const { category } = target.dataItem;
        return !!category && category !== '' ? labelText : null;
      });
      this.chart.legend.itemContainers.template.paddingTop = 1;
      this.chart.legend.itemContainers.template.paddingBottom = 1;
      this.chart.legend.itemContainers.template.paddingRight = 3;

      // truncate
      this.chart.legend.itemContainers.template.maxWidth = 275;
      this.chart.legend.itemContainers.template.truncate = true;

      let markerTemplate = this.chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = this.chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);

      if (legendCopyable) {
        // Add event listener to legend labels to enable copying to clipboard
        this.chart.legend.itemContainers.template.events.on('doublehit', async function (ev) {
          const { dataItem } = ev.target;
          if (dataItem) {
            const { dataContext } = dataItem;
            const { percent } = dataContext.values.value;
            const { value } = dataContext;
            const { category } = dataContext;
            const copyString = `${category}: ${value} — ${Number(percent).toFixed(2)}%`;
            await navigator.clipboard.writeText(copyString);
            message.info('Скопировано в буфер обмена');
          }
        });
        this.chart.legend.itemContainers.template.togglable = false;
      }
    }
    // hide small values
    // series.events.on("datavalidated", function (ev) {
    //   ev.target.slices.each(function (slice) {
    //     if (slice && slice.dataItem.values.value.percent < 1) {
    //       slice.dataItem.hide();
    //       slice.dataItem.legendDataItem.dispose();
    //     }
    //   });
    // });

    if (!showLabels) {
      series.labels.template.disabled = true;
    }

    if (data.length) {
      const colorSet = new am4core.ColorSet();

      const colors = data[0].color ? data.map((el) => el.color) : colorList ? colorList : COLORS;

      colorSet.list = colors.map((color) => new am4core.color(color));
      series.colors = colorSet;
    }

    this.chart.events.on('ready', (ev) => {
      this.hideIndicator();
    });
    this.chart.events.dispatch('ready');
  };

  showIndicator = () => {
    this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator.background.fill = am4core.color('#323c48');
    this.indicator.background.fillOpacity = 0.8;
    this.indicator.width = am4core.percent(100);
    this.indicator.height = am4core.percent(100);

    const indicatorLabel = this.indicator.createChild(am4core.Label);
    indicatorLabel.align = 'center';
    indicatorLabel.valign = 'middle';
    indicatorLabel.fontSize = 16;
    indicatorLabel.color = am4core.color('#9a9a9a');
    indicatorLabel.dy = 15;

    const spinner = this.indicator.createChild(am4core.Image);
    spinner.href = '/images/spinner.gif';
    spinner.align = 'center';
    spinner.valign = 'middle';
    spinner.horizontalCenter = 'middle';
    spinner.verticalCenter = 'middle';
    spinner.width = 24;
    spinner.height = 24;
    spinner.dy = -15;
  };

  hideIndicator = () => {
    if (this.indicator) this.indicator.hide();
  };

  loadDetails = async (category) => {
    const { nameKey, query, modalFilters } = this.props;
    const resultSet = await cubejsApi.load({
      ...query,
      filters: [
        {
          dimension: nameKey,
          operator: 'equals',
          values: [category],
        },
        ...modalFilters,
      ],
    });
    const items = resultSet.rawData().map((el, index) => ({
      id: ++index,
      ...el,
    }));
    this.setState({
      visible: true,
      items,
      category,
    });
  };

  hideDetails = (e) => {
    this.setState({
      visible: false,
    });
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { visible, items } = this.state;
    const { id, height, renderDetail, excelFields, exportExcel } = this.props;

    return (
      <>
        <div id={id} style={{ width: '100%', height }} />
        <Modal
          title={
            <Row style={{ paddingRight: 20 }} type="flex" justify="space-between">
              <div>
                <h5>{'Детализация по ' + this.state.category}</h5>
                <h5>{'Количество : ' + items.length}</h5>
              </div>
              {exportExcel ? (
                <ExportExcel
                  filename="Детализация"
                  data={items}
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                  fields={excelFields}
                />
              ) : null}
            </Row>
          }
          visible={visible}
          onCancel={this.hideDetails}
          width="96%"
          style={{
            marginTop: '10px',
          }}
          footer={null}
          bodyStyle={{
            // height: "80vh",
            overflowY: 'auto',
            paddingTop: '0',
          }}
          centered
        >
          {renderDetail(items)}
        </Modal>
      </>
    );
  }
}

PieChart.defaultProps = {
  loading: true,
  id: 'pie-chart',
  hideSmallValues: false,
  nameKey: 'category',
  dataKey: 'value',
  query: {},
  renderDetail: () => {},
  showLegend: true,
  showLabels: false,
  height: '300px',
  innerRadius: 50,
  legendPosition: 'bottom',
  exportExcel: false,
  excelFields: [],
  modalFilters: [],
  legendCopyable: false,
};

PieChart.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string,
  dataKey: PropTypes.string,
  query: PropTypes.object,
  renderDetail: PropTypes.func,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  height: PropTypes.string,
  innerRadius: PropTypes.number,
  legendPosition: PropTypes.string,
  exportExcel: PropTypes.bool,
  excelFields: PropTypes.array,
  modalFilters: PropTypes.array,
  legendCopyable: PropTypes.bool,
};
