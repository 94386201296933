import React from 'react';

const TrafficFlows = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/traffic-flows"
      title="traffic-flows"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default TrafficFlows;
