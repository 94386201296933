import React from 'react';
import { Table } from 'antd';

import { useEcologyReport } from './context';

import ExcelExport from '../../components/ExcelExport';

const COLUMNS = [
  {
    title: 'Наименование загрязняющего вещества',
    dataIndex: 'measureNameRU',
    key: 'measureNameRU',
  },
  {
    title: 'Максимальная разовая ПДК (мг/м3)',
    dataIndex: 'maxpokazatel',
    key: 'maxpokazatel',
  },
  {
    title: 'Среднесуточная ПДК (мг/м3)',
    dataIndex: 'pdk',
    key: 'pdk',
  },
  {
    title: 'Максимальная концентрация',
    dataIndex: 'maxvalue',
    key: 'maxvalue',
  },
  {
    title: 'Среднесуточная концентрация',
    dataIndex: 'avgvalue',
    key: 'avgvalue',
  },
  {
    title: 'Количество случаев',
    children: [
      {
        title: '>1 ПДК',
        dataIndex: 'pdk1',
        key: 'pdk1',
      },
      {
        title: '>5 ПДК',
        dataIndex: 'pdk5',
        key: 'pdk5',
      },
      {
        title: '>10 ПДК',
        dataIndex: 'pdk10',
        key: 'pdk10',
      },
    ],
  },
  {
    title: 'СИ',
    dataIndex: 'ci',
    key: 'ci',
  },
  {
    title: 'НП, %',
    dataIndex: 'np',
    key: 'np',
  },
];

export default function EcologyReportTable() {
  const { reportData } = useEcologyReport();

  return (
    <div style={{ marginTop: 30 }}>
      <ExcelExport fileName={'Отчет по загрязнению атмосферы'} />
      <Table rowKey={(record) => record.id} columns={COLUMNS} dataSource={reportData} bordered />
    </div>
  );
}
