import React, { cloneElement, useState } from 'react';

import RoadRepair from './RoadRepair';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';
import PlumbingSystem from '../RoadConstruction/PlumbingSystem';

const TABS = {
  'Ремонт дорог': <RoadRepair />,
  'Водопроводная система': <PlumbingSystem />,
};

const RoadConstruct = () => {
  const [tab, setTab] = useState(Object.keys(TABS)[0]);

  const onChangeTabs = (event) => {
    setTab(event.target.value);
  };

  return (
    <MainWrapper>
      <CaseMainTitle>ЖКХ</CaseMainTitle>
      <RadioGroup
        onChange={onChangeTabs}
        value={tab}
        list={Object.keys(TABS).map((key) => ({
          key: key,
          value: key,
        }))}
      />
      {Object.keys(TABS).map((nav, index) => {
        if (nav === tab) return cloneElement(TABS[nav], { key: index });
        else return null;
      })}
    </MainWrapper>
  );
};

export default RoadConstruct;
