import React from 'react';
import { Table } from 'antd';

function OtherDetails({ data, type }) {
  const columns = [
    {
      title: type === 'Appeals.region' ? 'Населенный пункт' : 'Регион',
      dataIndex: 'title',
      width: 500,
    },
    {
      title: 'Количество обращений',
      dataIndex: 'value',
      align: 'center',
    },
  ];
  return (
    <Table
      rowKey={(record) => record.title}
      columns={columns}
      dataSource={data || []}
      size="small"
      scroll={{ x: 'max-content' }}
      pagination={true}
    />
  );
}

export default OtherDetails;
