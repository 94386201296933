import React from 'react';
import ReactExport from 'react-export-excel';
import { Button } from 'antd';
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const ExcelExport = ({ filename, lists, style }) => {
  return (
    <ExcelFile
      element={
        <Button style={style} type="primary">
          Экспорт
        </Button>
      }
      filename={filename}
    >
      {lists &&
        lists.length &&
        lists.map((list, key) => {
          return (
            <ExcelSheet data={list.data} name={list.name} key={key}>
              {list.columns.map((column, c_index) => {
                return <ExcelColumn label={column.label} value={column.value} key={c_index} />;
              })}
            </ExcelSheet>
          );
        })}
    </ExcelFile>
  );
};

export default ExcelExport;
