import React from 'react';
const AppealsMapIframe = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/appeals-map"
      title="new-appeals"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default AppealsMapIframe;
