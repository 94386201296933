import React from 'react';
import { Select } from 'antd';

import styles from './SelectList.module.scss';

const { Option } = Select;

export function SelectList({
  label = '',
  list = [],
  showKey = null,
  valueKey = null,
  ...selectProps
}) {
  const getValue = (obj) => (valueKey ? obj[valueKey] : obj);

  const getShowTxt = (obj) => (showKey ? obj[showKey] : obj);

  const getKey = (obj) => (valueKey ? obj[valueKey] : obj);

  return (
    <div className={styles.select_list_wrapper}>
      <div className={styles.select_list_title}>{label}</div>
      <Select
        className={styles.select_list_component}
        placeholder="- Не выбрано -"
        theme="dark"
        {...selectProps}
      >
        {list.map((item) => (
          <Option value={getValue(item)} key={getKey(item)}>
            {getShowTxt(item)}
          </Option>
        ))}
      </Select>
    </div>
  );
}
