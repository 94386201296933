import React from 'react';

const BusinessMap = () => {
  return (
    <iframe
      src="https://bicloud.krgsc.kz/public/dashboards/24"
      title="buisness map"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};
export default BusinessMap;
