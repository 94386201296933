import React from 'react';

const Loader = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <img
      src="/images/spinner.gif"
      alt="Загрузка"
      width="24px"
      height="24px"
      style={{ marginBottom: 4 }}
    />
    <div style={{ fontSize: 16, lineHeight: 1, color: '#fff' }}>Loading data...</div>
  </div>
);

export default Loader;
