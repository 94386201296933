import React from 'react';
import { DatePicker } from 'antd';

import { useEcologyReport } from './context';

import FilterSelect from '../../components/FilterSelect';
import { FilterItem } from '../../components/newDesign/FilterItem/FilterItem';
import { FilterWrapper } from '../../components/newDesign/FilterWrapper/FilterWrapper';

function Filters() {
  const {
    // select options
    weekDaysList,
    regionList,
    stationList,
    measuresList,
    sourceList,
    typeList,
    // active
    period,
    weekDay,
    regionId,
    stationId,
    measureId,
    sourceId,
    typeId,
    // handlers
    handlePeriod,
    handleWeekDay,
    handleRegion,
    handleStation,
    handleMeasure,
    handleSource,
    handleType,
  } = useEcologyReport();

  return (
    <FilterWrapper>
      <FilterItem>
        <h3>Период</h3>
        <DatePicker.RangePicker
          onChange={handlePeriod}
          separator="—"
          value={[period.startDate, period.endDate]}
          showTime={{
            secondStep: 60,
            minuteStep: 15,
          }}
          size="small"
          style={{
            marginRight: 'auto',
            width: '100%',
          }}
        />
      </FilterItem>
      <FilterItem>
        <h3>День недели</h3>
        <FilterSelect
          options={weekDaysList}
          filterField="weekDay"
          handleChange={handleWeekDay}
          placeholder="День недели"
          maxWidth="100%"
          value={weekDay}
        />
      </FilterItem>
      <FilterItem>
        <div style={{ width: 330 }}>
          <h3>Регион</h3>
          <FilterSelect
            options={regionList}
            filterField="region"
            handleChange={handleRegion}
            placeholder="Регион"
            maxWidth="100%"
            value={regionId}
            mode="multiple"
          />
        </div>
      </FilterItem>
      <FilterItem>
        <h3>Станция</h3>
        <FilterSelect
          options={stationList}
          filterField="station"
          handleChange={handleStation}
          placeholder="Станция"
          maxWidth="100%"
          value={stationId}
        />
      </FilterItem>
      <FilterItem>
        <h3>Загрязняющее вещество</h3>
        <FilterSelect
          options={measuresList}
          filterField="measure"
          handleChange={handleMeasure}
          placeholder="Загрязняющее вещество"
          maxWidth="100%"
          value={measureId}
        />
      </FilterItem>
      <FilterItem>
        <h3>Источник</h3>
        <FilterSelect
          options={sourceList}
          filterField="source"
          handleChange={handleSource}
          placeholder="Источник"
          maxWidth="100%"
          value={sourceId}
        />
      </FilterItem>
      <FilterItem>
        <h3>Тип станции</h3>
        <FilterSelect
          options={typeList}
          filterField="type"
          handleChange={handleType}
          placeholder="Тип станции"
          maxWidth="100%"
          value={typeId}
        />
      </FilterItem>
    </FilterWrapper>
  );
}

export default Filters;
