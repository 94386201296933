import React, { Component } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import PropTypes from 'prop-types';

const COLORS = ['#25abe0', '#2a73dc', '#0a9574', '#a60c0a', '#3c2786', '#a05ccf'];

export default class VerticalBarChart extends Component {
  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { loading, id } = this.props;

    // Create chart instance
    const chart = am4core.create(id, am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;

    this.chart = chart;
    if (loading) this.showIndicator();
    this.initChart();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    if (loading && !prevProps.loading) {
      this.showIndicator();
    } else if (!loading && prevProps.loading) {
      this.initChart();
    }
  }

  initChart = () => {
    const {
      data,
      category,
      showLegend,
      categoryText,
      values,
      valueText,
      dateAxis,
      labelMaxWidth,
      scrollStart,
      scrollEnd,
      stacked,
      rotate,
      showValues,
    } = this.props;
    if (!data) return;

    // Add data
    this.chart.data = data.length ? data : [];

    // legend
    if (showLegend) {
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.fontSize = 11;
      this.chart.legend.position = 'bottom';
      this.chart.legend.labels.template.text = '{name}[/]';
      this.chart.legend.itemContainers.template.paddingTop = 1;
      this.chart.legend.itemContainers.template.paddingBottom = 1;

      let markerTemplate = this.chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = this.chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
    }
    // colors
    const colors = values.length
      ? values[0].color
        ? values.map((value) => value.color)
        : COLORS
      : [];
    this.chart.colors.list = colors.map((color) => am4core.color(color));

    // Create axes
    this.chart.xAxes.clear();
    if (!dateAxis) {
      const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = category;
      if (categoryText) categoryAxis.title.text = categoryText;
      // categoryAxis.renderer.grid.template.location = 0;
      // categoryAxis.renderer.minGridDistance = 40;

      const label = categoryAxis.renderer.labels.template;
      // label.truncate = true;
      label.fontSize = 12;
      label.lineHeight = 12;
      label.wrap = true;
      label.maxWidth = labelMaxWidth;
      if (rotate) {
        label.horizontalCenter = 'right';
        label.verticalCenter = 'middle';
        label.rotation = 310;
      }
      categoryAxis.renderer.grid.template.disabled = true;

      if (values.length > 1 && !stacked) {
        categoryAxis.renderer.cellStartLocation = 0.2; // 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8; // 0.8;
      } else {
        categoryAxis.renderer.cellStartLocation = 0.1; // 0.2;
        categoryAxis.renderer.cellEndLocation = 0.9; // 0.8;
      }
    } else {
      const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      if (categoryText) dateAxis.title.text = categoryText;
      // dateAxis.renderer.minGridDistance = 10;
      // dateAxis.renderer.labels.template.fontSize = 12;
      if (rotate) {
        dateAxis.renderer.labels.template.horizontalCenter = 'left';
        dateAxis.renderer.labels.template.verticalCenter = 'middle';
        dateAxis.renderer.labels.template.rotation = 60;
      }
      dateAxis.renderer.grid.template.disabled = true;

      // dateAxis.parseDates = false;

      // Set input format for the dates
      // this.chart.dateFormatter.inputDateFormat = inputDateFormat;
      // chart.dataDateFormat = "YYYY-MM-DDTJJ:NN:SS";
      // chart.dateFormatter.dateFormat = "dd";
      // dateAxis.dateFormatter = new am4core.DateFormatter();
      // dateAxis.dateFormatter.dateFormat = "dd";
      // dateAxis.dateFormats.setKey("day", dateFormat);
      // dateAxis.periodChangeDateFormats.setKey("day", dateFormat);
      // dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy[/]");

      // if (values.length > 1 && !stacked) {
      dateAxis.renderer.cellStartLocation = 0.1;
      dateAxis.renderer.cellEndLocation = 0.9;
      // }
    }

    this.chart.yAxes.clear();
    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    if (valueText) valueAxis.title.text = valueText;
    valueAxis.renderer.labels.template.fontSize = 12;

    // Create series
    this.chart.series.clear();
    values.forEach((value, i) => {
      const { key, title } = value;
      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = key;
      series.dataFields[dateAxis ? 'dateX' : 'categoryX'] = category;
      series.name = title;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      if (values.length > 1 && !stacked) {
        series.columns.template.width = am4core.percent(100);
      } else {
        series.columns.template.width = am4core.percent(100);
      }
      series.stacked = stacked;
      series.columns.template.column.cornerRadiusTopRight = 5;
      series.columns.template.column.cornerRadiusTopLeft = 5;

      if (showValues) {
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{valueY}';
        labelBullet.dy = -10;
        labelBullet.label.fill = am4core.color('#fff');
        labelBullet.fontSize = 10;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
      }
    });

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.scrollbarX = new am4core.Scrollbar();
    this.chart.scrollbarX.start = scrollStart;
    this.chart.scrollbarX.end = scrollEnd;
    this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
    this.chart.events.on('ready', (ev) => {
      this.hideIndicator();
    });
    this.chart.events.dispatch('ready');
  };

  showIndicator = () => {
    this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator.background.fill = am4core.color('#323c48');
    this.indicator.background.fillOpacity = 0.8;
    this.indicator.width = am4core.percent(100);
    this.indicator.height = am4core.percent(100);

    const indicatorLabel = this.indicator.createChild(am4core.Label);
    indicatorLabel.text = 'Loading data...';
    indicatorLabel.align = 'center';
    indicatorLabel.valign = 'middle';
    indicatorLabel.fontSize = 16;
    indicatorLabel.color = am4core.color('#9a9a9a');
    indicatorLabel.dy = 15;

    const spinner = this.indicator.createChild(am4core.Image);
    spinner.href = '/images/spinner.gif';
    spinner.align = 'center';
    spinner.valign = 'middle';
    spinner.horizontalCenter = 'middle';
    spinner.verticalCenter = 'middle';
    spinner.width = 24;
    spinner.height = 24;
    spinner.dy = -15;
  };

  hideIndicator = () => {
    if (this.indicator) this.indicator.hide();
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: '100%', height }} />;
  }
}

VerticalBarChart.defaultProps = {
  loading: true,
  id: 'vertical-bar-chart',
  category: 'category',
  dateAxis: false,
  inputDateFormat: 'yyyy-MM-ddT:HH:mm:ss.000',
  dateFormat: 'dd MMM',
  stacked: false,
  rotate: false,
  labelMaxWidth: 120,
  scrollStart: 0,
  scrollEnd: 1,
  showValues: false,
  height: '300px',
  showLegend: true,
};

VerticalBarChart.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  values: PropTypes.array.isRequired,
  valueText: PropTypes.string,
  dateAxis: PropTypes.bool,
  inputDateFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  stacked: PropTypes.bool,
  rotate: PropTypes.bool,
  labelMaxWidth: PropTypes.number,
  scrollStart: PropTypes.number.isRequired,
  scrollEnd: PropTypes.number.isRequired,
  showValues: PropTypes.bool,
  height: PropTypes.string,
  showLegend: PropTypes.bool,
};
