import React from 'react';

const Weather = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/weather"
      title="weather"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};
export default Weather;
