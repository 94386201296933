import React from 'react';

const GosUslyugy = () => {
  return (
    <iframe
      src="https://bicloud.krgsc.kz/public/dashboards/22"
      title="gosuslugy"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default GosUslyugy;
