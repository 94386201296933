import React from 'react';

const CommodityExchange = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/commodity-exchange"
      title="Commodity Exchange"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default CommodityExchange;
