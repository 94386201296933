export const navigation = [
  {
    title: 'Общественная безопасность',
    link: '/safety',
    icon: './images/nav_icons/1',
  },
  {
    title: 'Экономика',
    link: '/economic',
    icon: './images/nav_icons/11',
  },
  {
    title: 'Промышленность',
    link: '/commodity-exchange',
    icon: './images/nav_icons/4',
  },
  {
    title: 'Экология',
    link: '/ecology',
    icon: './images/nav_icons/3',
  },
  {
    title: 'Здравохранение',
    link: '/healthcare',
    icon: './images/nav_icons/7',
  },
  {
    title: 'Образование',
    link: '/education',
    icon: './images/nav_icons/12',
  },
  {
    title: 'Обращения граждан',
    // link: "/price-monitoring",
    link: '/social',
    icon: './images/nav_icons/5',
  },
  {
    title: 'Оперативная сводка',
    // link: "/price-monitoring",
    link: '/operational-summary',
    icon: './images/nav_icons/8',
  },
  {
    title: 'ЖКХ',
    link: '/road-construction',
    icon: './images/nav_icons/5',
  },
  {
    title: 'Транспорт',
    link: '/transport',
    icon: './images/nav_icons/9',
  },
  // {
  //   title: "Государственные услуги",
  //   link: "/404",
  //   icon: "./images/nav_icons/10"
  // },
  {
    title: 'Прогнозирование',
    link: '/forecast/population',
    icon: './images/nav_icons/14',
  },
  {
    title: 'Аналитические индикаторы',
    link: '/indicators',
    icon: './images/nav_icons/13',
  },
  // {
  //   title: "Карты",
  //   link: "/404",
  //   icon: "./images/nav_icons/6"
  // },
  {
    title: 'Видеопоток',
    link: '/videostream',
    icon: './images/nav_icons/8',
  },
];
