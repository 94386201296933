import React from 'react';

const ForeignTrade = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/foreign-trade"
      title="foreign-trade"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default ForeignTrade;
