import React from 'react';
import { Circle, Map, Polygon, YMaps } from 'react-yandex-maps';

import { useEcologyReport } from './context';

const mapState = {
  center: [48.26187, 72.854118],
  zoom: 7,
};
const COLORS = {
  green: '#2BA6FF',
  red: '#FF7324',
  yellow: '#FFC224',
};

const POLIGONS = {
  'г. Караганда': [[49.80776, 73.088504], 10000],
  'г. Балхаш': [[46.843721, 74.977301], 10000],
  'г. Жезказган': [[47.799711, 67.71409], 10000],
  'г. Сарань': [[49.801993, 72.828387], 10000],
  'г. Темиртау': [[50.058761, 72.953424], 10000],
};
export default function EcologyMap() {
  const { mapData } = useEcologyReport();

  return (
    <YMaps query={{ lang: 'ru_RU', load: 'package.full' }}>
      <Map defaultState={mapState} width="100%" height="100%">
        {mapData.map((city, index) => (
          <Circle
            key={index}
            geometry={POLIGONS[city.locality]}
            options={{
              draggable: true,
              fillColor: '#fff',
              strokeColor: COLORS[city.localityColor],
              opacity: 0.7,
              strokeWidth: 8,
            }}
          />
        ))}
      </Map>
    </YMaps>
  );
}
