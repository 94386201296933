import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { navigation } from '../data/constant';

let NAV_ITEMS = [];

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let user = window.localStorage.getItem('user_params');
    if (user) this.getNav(user);
  }

  getNav = (payload) => {
    const user = JSON.parse(payload);
    const { tabs } = user.roles[0];
    NAV_ITEMS = navigation.filter((nav) => {
      if (tabs.find((tab) => tab.url.split('cc.krgsc.kz')[1] === nav.link)) return true;
      else return false;
    });
  };

  renderNav = (nav) => {
    const route = this.props.location.pathname;
    return (
      <ul>
        {nav.map((el, index) => (
          <li key={index} className={el.link === route ? 'active' : ''}>
            <Link to={el.link}>
              <img src={el.icon + '.png'} alt="navigation" className="dark" />
              <img src={el.icon + ',1.png'} alt="navigation" className="white" />
              <h3>{el.title}</h3>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <nav>
        {this.renderNav(NAV_ITEMS.slice(0, 4))}
        {this.renderNav(NAV_ITEMS.slice(4, 8))}
        {this.renderNav(NAV_ITEMS.slice(8, 12))}
        {this.renderNav(NAV_ITEMS.slice(12, NAV_ITEMS.length))}
      </nav>
    );
  }
}

export default TopNavigation;
