import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import Filters from './components/Filters';
import Graphs from './components/Graphs';
import Indicators from './components/Indicators';
import RoadRepairMap from './components/RoadRepairMap';
import RoadRepairTable from './components/RoadRepairTable';

import request from '../../../utils/request';

const RoadRepair = () => {
  const [allContracts, setAllContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [works, setWorks] = useState([]);
  const [streets, setStreets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [results, setResults] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const handleFilter = useCallback(
    (filterObj) => {
      let contracts = allContracts.filter((contr) => {
        let existKeys = ['checkbox'];
        Object.keys(filterObj).forEach((k) => {
          if (_.isEmpty(filterObj[k])) return;
          switch (k) {
            case 'status':
              if (filterObj[k].some((o2) => o2 === contr.mgzRoadwayDTO.status)) existKeys.push(k);
              break;
            case 'work':
              if (
                _.intersection(
                  contr.mgzProcessedRoadwayDTO.workCategory.map((e) => e.category),
                  filterObj[k].map((b) => b.category)
                ).length !== 0
              )
                existKeys.push(k);
              break;
            case 'road':
              if (
                _.intersection(
                  contr.mgzProcessedRoadwayDTO.roadCategory.map((e) => e.category),
                  filterObj[k].map((b) => b.category)
                ).length !== 0
              )
                existKeys.push(k);
              break;
            case 'customer':
              if (filterObj[k].some((o2) => o2 === contr.mgzRoadwayDTO.customer)) existKeys.push(k);
              break;

            case 'result':
              if (
                contr.mgzProcessedRoadwayDTO.mgzRoadwayExpertiseDTO &&
                filterObj[k].some((o2) =>
                  _.isEqual(o2, contr.mgzProcessedRoadwayDTO.mgzRoadwayExpertiseDTO.result)
                )
              )
                existKeys.push(k);
              break;
            case 'checkbox':
              if (
                contr.mgzProcessedRoadwayDTO.warrantyEndDate >
                moment().format('YYYY-MM-DD').toString
              )
                existKeys.push(k);

              break;
            case 'startDate':
              if (moment(contr.mgzProcessedRoadwayDTO.startDate).isAfter(filterObj[k]))
                existKeys.push(k);
              break;
            case 'endDate':
              if (!moment(contr.mgzProcessedRoadwayDTO.endDate).isAfter(filterObj[k]))
                existKeys.push(k);
              break;
            case 'suppliers':
              if (filterObj[k].some((o2) => o2 === contr.mgzRoadwayDTO.supplier)) existKeys.push(k);
              break;
            default:
          }
        });
        if (existKeys.length === Object.keys(filterObj).length) return true;
        return false;
      });
      setFilteredContracts(
        contracts.map((i) => ({
          ...i,
          roadCategory: i.mgzProcessedRoadwayDTO?.roadCategory?.map((e) => e.category).join(', '),
          workCategory: i.mgzProcessedRoadwayDTO?.workCategory?.map((e) => e.category).join(', '),
          result: i.mgzProcessedRoadwayDTO?.mgzRoadwayExpertiseDTO?.result?.result,
        }))
      );
    },
    [allContracts]
  );

  useEffect(() => {
    const apiPaths = [
      { path: '/ccadmin/api/v1/mgzroadway/getWork', setFunc: setWorks },
      { path: '/ccadmin/api/v1/mgzroadway/getStreet', setFunc: setStreets },
      { path: '/ccadmin/api/v1/mgzroadway/getCategory', setFunc: setCategories },
      { path: '/ccadmin/api/v1/mgzroadway/getResult', setFunc: setResults },
    ];
    (async () => {
      const results = await Promise.allSettled(
        apiPaths.map((api) =>
          request(api.path, {
            method: 'GET',
          })
        )
      );

      results.forEach(({ value, status }, index) => {
        if (status === 'fulfilled') {
          apiPaths[index].setFunc(value);
        }
      });
    })();

    Promise.all([
      request('/ccadmin/api/v1/mgzroadway/processedRoadways?type=PROCESSED', {
        method: 'GET',
      }),
      request('/ccadmin/api/v1/mgzroadway/processedRoadways?type=FINAL', {
        method: 'GET',
      }),
      request('/ccadmin/api/v1/mgzroadway/suppliers', {
        method: 'GET',
      }),
    ]).then(([processed, final, suppliers]) => {
      setSuppliers(suppliers);
      let allC = [...processed, ...final];
      setAllContracts(allC);
      setStatuses([...new Set(allC.map((item) => item?.mgzRoadwayDTO?.status))]);
      setCustomers([...new Set(allC.map((item) => item?.mgzRoadwayDTO?.customer))]);
      handleFilter({ checkbox: false });
      setFilteredContracts(
        allC.map((i) => ({
          ...i,
          roadCategory: i.mgzProcessedRoadwayDTO?.roadCategory?.map((e) => e.category).join(', '),
          workCategory: i.mgzProcessedRoadwayDTO?.workCategory?.map((e) => e.category).join(', '),
          result: i.mgzProcessedRoadwayDTO?.mgzRoadwayExpertiseDTO?.result?.result,
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        overflowX: 'hide',
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <div>
        <Filters
          filters={{
            works,
            streets,
            categories,
            results,
            customers,
            statuses,
            suppliers,
          }}
          handleFilter={handleFilter}
        />
      </div>
      <Row>
        <RoadRepairMap contracts={filteredContracts} works={works} />
      </Row>
      <Row>
        <RoadRepairTable contracts={filteredContracts} />
      </Row>
      <Row>
        <Indicators contracts={filteredContracts} />
      </Row>
      <Row>
        <Graphs
          contracts={filteredContracts}
          works={works}
          streets={streets}
          categories={categories}
          results={results}
        />
      </Row>
    </div>
  );
};

export default RoadRepair;
