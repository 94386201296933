import React, { Component } from 'react';

import AppealsMonitoring from './AppealsMonitoring/AppealsMonitoring';
import AnalyticaTelephonyi from './AnalyticaTelephonyi';
import AppealsCitizen from './AppealsCitizen';
import AppealsMapIframe from './AppealsMapIframe';
import CallControlIframe from './CallControlIframe';
import CategoryAnalytics from './CategoryAnalytics';
import NewAppeals from './NewAppeals';
import RegionAnalytics from './RegionAnalytics';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';

const NAME_COMP = {
  'Обращения граждан': <AppealsCitizen />,
  'Работа SENIM109': <NewAppeals />,
  'Мониторинг исполнения': <AppealsMonitoring />,
  'Аналитика категорий': <CategoryAnalytics />,
  'Аналитика регионов': <RegionAnalytics />,
  'Карта обращений': <AppealsMapIframe />,
  'Аналитика телефонии': <AnalyticaTelephonyi />,
  'Контроль обращений': <CallControlIframe />,
};
class Social extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: Object.keys(NAME_COMP)[0],
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { subtabs } = this.props;
    return (
      <MainWrapper>
        <CaseMainTitle>Обращения граждан</CaseMainTitle>
        <RadioGroup
          onChange={this.radioChange}
          value={this.state.radio}
          list={subtabs.map((i) => ({
            key: i.name,
            value: i.name,
          }))}
        />
        {Object.keys(NAME_COMP).map((nav) => {
          if (nav === this.state.radio) return NAME_COMP[nav];
          else return null;
        })}
      </MainWrapper>
    );
  }
}

export default Social;
