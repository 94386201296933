import React from 'react';

const Currency = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/currency"
      title="currency"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Currency;
