import React, { Component } from 'react';
import { Button, Col, Icon, Input, Result, Row } from 'antd';

import ExcelExport from './ExcelExport';
import Indicator from './Indicator';
import TableView from './TableView';

import LoadingSpinner from '../../components/LoadingSpinner';
import { FilterItem } from '../../components/newDesign/FilterItem/FilterItem';
import { FilterWrapper } from '../../components/newDesign/FilterWrapper/FilterWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';
import { RowWrapper } from '../../components/newDesign/RowWrapper/RowWrapper';
import { SelectList } from '../../components/newDesign/SelectList/SelectList';
import request from '../../utils/request';
const { Search } = Input;
class AnalyticalIndicators extends Component {
  state = {
    data: [],
    currentSphere: {
      branches: [],
    },
    selectedBranch: null,
    selectedRegion: null,
    indicators: [],
    filteredIndicators: [],
    loading: true,
    view: 'graph',
    exportData: [],
    regions: [],
    documents: [],
    selectedDocument: null,
  };

  componentDidMount() {
    request('/api/data/', { credentials: 'omit' }).then((r) => {
      this.setState(
        {
          data: r.spheres,
          currentSphere: r.spheres[0],
          selectedBranch: r.spheres[0].branches[0],
          regions: r.regions,
          selectedRegion: '1',
          documents: r.documents,
        },
        () => this.getData()
      );
    });
  }

  getData = () => {
    const { selectedBranch, currentSphere, selectedRegion, selectedDocument } = this.state;
    let query = `?sphere=${currentSphere.sphere}&branch=${selectedBranch}&region=${selectedRegion}`;
    if (selectedDocument) query += '&doc=' + selectedDocument;
    request('/api/data/indicators' + query, { credentials: 'omit' }).then((r) => {
      this.setState(
        {
          indicators: r[0].branches[0].indicators,
          loading: false,
          filteredIndicators: r[0].branches[0].indicators,
        },
        () => this.prepareExport()
      );
    });
  };

  prepareExport = () => {
    const { indicators } = this.state;
    const exportData = indicators.map((el) => {
      return {
        name: el.indicator,
        data: this.parseData(el),
        columns: [
          { label: 'Год', value: 'year' },
          { label: 'План', value: 'plan' },
          { label: 'Факт', value: 'fact' },
        ],
      };
    });
    this.setState({ exportData });
  };

  parseData = (indicator) => {
    const uniqLabel = Array.from(
      new Set([...indicator.fact.data, ...indicator.plan.data].map((e) => e.label))
    );
    const data = uniqLabel.map((label) => {
      const plan = indicator.plan.data.find((e) => e.label === label);
      const fact = indicator.fact.data.find((e) => e.label === label);
      return {
        year: label + '',
        plan: plan ? plan.value : null,
        fact: fact ? fact.value : null,
      };
    });
    return data;
  };

  sphereChanged = (e) => {
    const { data } = this.state;
    let obj = data.find((el) => el.sphere === e.target.value);
    this.setState(
      {
        currentSphere: obj,
        selectedBranch: obj.branches[0],
        loading: true,
      },
      () => this.getData()
    );
  };

  handleRegion = (e) => {
    this.setState(
      {
        selectedRegion: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  handleBranch = (e) => {
    this.setState(
      {
        selectedBranch: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  searchIndicators = (val) => {
    if (val) {
      let filteredIndicators = this.state.indicators.filter((el) => {
        return el.indicator.toLowerCase().includes(val);
      });
      this.setState({ filteredIndicators });
    } else {
      this.setState({
        filteredIndicators: this.state.indicators,
      });
    }
  };

  changeView = (e) => {
    this.setState({
      view: e,
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  changedDocument = (e) => {
    this.setState(
      {
        selectedDocument: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  render() {
    const { data, currentSphere, filteredIndicators, loading, regions, documents } = this.state;
    return (
      <div className="p-10">
        <RadioGroup
          onChange={this.sphereChanged}
          value={currentSphere.sphere}
          list={data.map((el) => ({
            key: el.sphere,
            value: el.sphere,
          }))}
        />
        <FilterWrapper>
          <FilterItem>
            <SelectList
              value={this.state.selectedBranch}
              onChange={this.handleBranch}
              list={currentSphere.branches}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              value={this.state.selectedRegion}
              onChange={this.handleRegion}
              showKey="label"
              valueKey="value"
              list={Object.entries(regions).map(([key, value]) => ({
                value: key,
                label: regions[key],
              }))}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              defaultValue={this.state.view}
              onChange={this.changeView}
              showKey="label"
              valueKey="value"
              list={[
                {
                  value: 'graph',
                  label: 'Диаграмма',
                },
                {
                  value: 'table',
                  label: 'Таблица',
                },
              ]}
            />
          </FilterItem>
          <FilterItem>
            <ExcelExport
              style={{ width: '100%' }}
              filename={currentSphere.sphere + ': ' + this.state.selectedBranch}
              lists={this.state.exportData}
            />
          </FilterItem>
          <FilterItem>
            <Search
              className="dark_input"
              placeholder="Поиск по индикатору"
              onSearch={(value) => this.searchIndicators(value)}
              style={{ width: '100%', margin: '0' }}
              allowClear
            />
          </FilterItem>
        </FilterWrapper>

        <Row className="mt20">
          <Col span={18}>
            <div className="indicators">
              <LoadingSpinner
                className="flex-full flex layout-centered"
                loading={loading}
                size={46}
              >
                <RowWrapper>
                  {this.state.view === 'graph' &&
                    filteredIndicators.map((indicator, key) => (
                      <Indicator key={key} id={`indicator-${key}`} indicator={indicator} />
                    ))}

                  {this.state.view === 'table' &&
                    filteredIndicators.map((indicator, key) => (
                      <TableView key={key} indicator={indicator} />
                    ))}

                  {filteredIndicators.length === 0 && (
                    <Result icon={<Icon type="container" />} title="Индикаторы не найдены" />
                  )}
                </RowWrapper>
              </LoadingSpinner>
            </div>
          </Col>
          <Col span={6}>
            <div className="documents-list">
              {documents.map((e) => (
                <div
                  key={e}
                  onClick={() => this.changedDocument(e)}
                  className={this.state.selectedDocument === e ? 'active' : ''}
                >
                  {e}
                </div>
              ))}
              {this.state.selectedDocument && (
                <Button
                  className="mt10"
                  type="primary"
                  block
                  onClick={() => this.changedDocument(null)}
                >
                  Сбросить
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnalyticalIndicators;
