import React from 'react';

import styles from './ChartCard.module.scss';

export function ChartCard({ title = null, style = null, children }) {
  return (
    <div className={styles.chart_card} style={style}>
      {title && <div className={styles.chart_card_title}>{title}</div>}
      <div className={styles.chart_card_content}>{children}</div>
    </div>
  );
}
