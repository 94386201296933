import React from 'react';

const Calendar = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/events-calendar"
      title="Calendar"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Calendar;
