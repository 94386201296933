import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import PieChart2 from '../../../../../components/visualizations/PieChart';

import styles from './graphs.module.scss';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Graphs = ({ contracts, ...props }) => {
  const [pieDataWork, setPieDataWork] = useState([]);
  const [pieDataRoad, setPieDataRoad] = useState([]);
  const [barDataStreet, setBarDataStreet] = useState([]);
  const [pieDataResult, setPieDataResult] = useState([]);
  const [pieDataRefKopfCode, setPieDataRefKopfCode] = useState([]);
  const [pieDataFact, setPieDataFact] = useState([]);
  const [pieDataKsProc, setPieDataKsProc] = useState([]);
  const [pieDataRoadBlock, setPieDataRoadBlock] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (contracts.length > 0) {
      getPieDataWorkCategory();
      getPieDataRoadCategory();
      getBarDataStreet();
      getPieDataResult();
      getPieDataRefKopfCode();
      getPieDatafactTradeMethod();
      getPieDataKsProc();
      getPieDataRoadBlockCategory();
    } else {
      setPieDataWork([]);
      setPieDataRoad([]);
      setBarDataStreet([]);
      setPieDataResult([]);
      setPieDataRefKopfCode([]);
      setPieDataFact([]);
      setPieDataKsProc([]);
      setPieDataRoadBlock([]);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  const getPieDataWorkCategory = () => {
    let all = [];
    contracts.forEach((element) => {
      element.mgzProcessedRoadwayDTO.workCategory.forEach((e) => {
        all.push(e.category);
      });
    });
    let pieData = [];
    props.works.forEach((e) => {
      if (countOccurrences(all, e.category) !== 0) {
        pieData.push({
          name: e.category,
          value: countOccurrences(all, e.category),
        });
      }
    });
    setPieDataWork(pieData);
    // eslint-disable-next-line no-console
  };

  const getPieDataRoadCategory = () => {
    let all = [];
    contracts.forEach((element) => {
      element.mgzProcessedRoadwayDTO.roadCategory.forEach((e) => {
        all.push(e.category);
      });
    });
    let pieData = [];
    props.categories.forEach((e) => {
      if (countOccurrences(all, e.category) !== 0) {
        pieData.push({
          name: e.category,
          value: countOccurrences(all, e.category),
        });
      }
    });
    setPieDataRoad(pieData);
  };

  const getBarDataStreet = () => {
    let streetsObj = {};
    contracts.forEach((element) => {
      element.mgzProcessedRoadwayDTO.street.forEach((e) => {
        if (streetsObj[e.name])
          streetsObj[e.name] = streetsObj[e.name] + element.mgzProcessedRoadwayDTO.length;
        else streetsObj[e.name] = element.mgzProcessedRoadwayDTO.length;
      });
    });
    setBarDataStreet(
      Object.keys(streetsObj).map((k) => ({
        name: k,
        value: streetsObj[k],
      })) || []
    );
  };

  const getPieDataRefKopfCode = () => {
    let count = _.countBy(contracts, 'mgzRoadwayDTO.refKopfCode');
    setPieDataRefKopfCode(Object.keys(count).map((e) => ({ name: e, value: count[e] })));
  };

  const getPieDataResult = () => {
    let all = [];
    contracts.forEach((element) => {
      if (element.mgzProcessedRoadwayDTO.mgzRoadwayExpertiseDTO) {
        all.push(element.mgzProcessedRoadwayDTO.mgzRoadwayExpertiseDTO.result.result);
      }
    });
    let pieData = [];
    props.results.forEach((e) => {
      if (countOccurrences(all, e.result) !== 0) {
        pieData.push({
          name: e.result,
          value: countOccurrences(all, e.result),
        });
      }
    });
    setPieDataResult(pieData);
  };

  const getPieDatafactTradeMethod = () => {
    let count = _.countBy(contracts, 'mgzRoadwayDTO.factTradeMethod');
    setPieDataFact(Object.keys(count).map((e) => ({ name: e, value: count[e] })));
  };

  const getPieDataKsProc = () => {
    let count = _.countBy(contracts, 'mgzRoadwayDTO.ksProc');
    setPieDataKsProc(Object.keys(count).map((e) => ({ name: e, value: count[e] })));
  };

  const getPieDataRoadBlockCategory = () => {
    let count = _.countBy(contracts, 'mgzProcessedRoadwayDTO.roadBlockCategory');
    setPieDataRoadBlock(Object.keys(count).map((e) => ({ name: e, value: count[e] })));
  };

  return (
    <>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          {pieDataWork.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Категории ремонта</h3>
              <PieChart2
                colorList={COLORS}
                id="catRem"
                showLegend={false}
                totalFontSize={16}
                data={pieDataWork}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pieDataRoad.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Категории дорог</h3>
              <PieChart2
                colorList={COLORS}
                id="catRoad"
                showLegend={false}
                totalFontSize={16}
                data={pieDataRoad}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {barDataStreet.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Протяженность ремонта дорог в разрезе улиц</h3>
              <ResponsiveContainer width="100%" aspect={1.5}>
                <BarChart
                  layout="vertical"
                  data={barDataStreet}
                  margin={{
                    top: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" hide orientation="top" />
                  <YAxis
                    type="category"
                    orientation="right"
                    width={150}
                    padding={{ left: 20 }}
                    dataKey="name"
                  />
                  <Tooltip />

                  <Bar dataKey="value" fill="#8884d8" />
                  <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pieDataResult.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Результат экспертизы ремонта</h3>
              <PieChart2
                colorList={COLORS}
                id="expertRes"
                showLegend={false}
                totalFontSize={16}
                data={pieDataResult}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          {pieDataRefKopfCode.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Оганизационно-правовые формы подрядчиков</h3>
              <PieChart2
                colorList={COLORS}
                id="pieDataRefKopfCode"
                showLegend={false}
                totalFontSize={16}
                data={pieDataRefKopfCode}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pieDataRoadBlock.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Перекрытие дорог</h3>
              <PieChart2
                colorList={COLORS}
                id="pieDataRoadBlock"
                showLegend={false}
                totalFontSize={16}
                data={pieDataRoadBlock}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pieDataKsProc.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Местное содержание закупок</h3>
              <PieChart2
                colorList={COLORS}
                id="pieDataKsProc"
                showLegend={false}
                totalFontSize={16}
                data={pieDataKsProc}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pieDataFact.length > 0 && (
            <div className={styles.graph_wrapper}>
              <h3 className={styles.title}>Фактические способы закупки</h3>{' '}
              <PieChart2
                colorList={COLORS}
                id="pieDataFact"
                showLegend={false}
                totalFontSize={16}
                data={pieDataFact}
                nameKey="name"
                dataKey="value"
                showTotal
                height="250px"
                loading={loading}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Graphs;
