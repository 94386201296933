import React, { Component } from 'react';

import styles from './index.module.scss';

class Table extends Component {
  constructor(props) {
    super(props);

    this.tLabels = props.headers.map((header) => header.label);
    this.tKeys = props.headers.map((header) => header.key);
    this.height = props.height || '40vh';

    this.state = {
      sort: { col: 0, dir: 'asc' },
    };

    this.keyColumn = this.keyColumn.bind(this);
    this.selectedRow = this.selectedRow.bind(this);
    this.setSorting = this.setSorting.bind(this);
  }

  selectedRow(index) {
    this.props.onRowClick(index);
  }

  keyColumn(key, i, index, obj) {
    let content = (
      <td key={index}>
        <p>{obj[key] ? obj[key] : '-'}</p>
      </td>
    );
    if (!obj[key])
      return (
        <td key={index}>
          <p>-</p>
        </td>
      );
    switch (key) {
      case 'text':
        content = (
          <td key={index}>
            <input type="checkbox" id={`text_checkbox${i}`} style={{ display: 'none' }}></input>
            <div id="hidden">
              <p>{obj[key] ? obj[key] : '-'}</p>
            </div>
            <label htmlFor={`text_checkbox${i}`} className="clickable">
              Подробнее/Скрыть
            </label>
          </td>
        );
        break;
      case 'result':
        content = (
          <td key={index}>
            <input type="checkbox" id={`result_checkbox${i}`} style={{ display: 'none' }}></input>
            <div id="hidden">
              <p>{obj[key] ? obj[key] : '-'}</p>
            </div>
            <label htmlFor={`result_checkbox${i}`} className="clickable">
              Подробнее/Скрыть
            </label>
          </td>
        );
        break;
      case 'files':
        content = (
          <td key={index}>
            {obj[key].map((el, file_key) => {
              return (
                <a
                  href={el.link}
                  key={file_key}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'block',
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {el.name}
                </a>
              );
            })}
          </td>
        );
        break;
      default:
        break;
    }
    return content;
  }

  setSorting(col) {
    const dir = this.state.sort.dir === 'asc' ? 'desc' : 'asc';
    this.setState({
      sort: { col, dir },
    });
  }

  render() {
    const { objects } = this.props;
    const { col, dir } = this.state.sort;

    objects.sort((lhs, rhs) => {
      const key = this.tKeys[col];
      const left = lhs[key];
      const right = rhs[key];

      let order = 0;
      if (left < right) {
        order = -1;
      } else if (right < left) {
        order = 1;
      }

      return dir === 'asc' ? order : -order;
    });

    return (
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              {this.tLabels.map((header, i) => (
                <th
                  key={i}
                  onClick={() => this.setSorting(i)}
                  className={'clickable'}
                  data-sort={this.state.sort.col === i ? this.state.sort.dir : ''}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ maxHeight: this.height }}>
            {this.props.onRowClick
              ? objects.map((obj, i) => (
                  <tr key={i} className="clickable" onClick={() => this.selectedRow(i)}>
                    {this.tKeys.map((key, index) => {
                      return (
                        <td key={index}>
                          <p>{obj[key] ? obj[key] : '-'}</p>
                        </td>
                      );
                    })}
                  </tr>
                ))
              : objects.map((obj, i) => (
                  <tr key={i}>
                    {this.tKeys.map((key, index) => {
                      return this.keyColumn(key, i, index, obj);
                    })}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
