import React, { createContext, useContext, useEffect, useState } from 'react';

import request from '../../utils/request';

const ArmContext = createContext({});

function useArm() {
  return useContext(ArmContext);
}

function ArmProvider({ children }) {
  // -- STATE --
  const [loading, setLoading] = useState(false);
  //
  const [periodList, setPeriodList] = useState([]);
  const [directionsList, setDirectionsList] = useState([]);
  const [aimsList, setAimsList] = useState([]);

  const [periodId, setPeriodId] = useState();
  const [directionId, setDirectionId] = useState();
  const [aimId, setAimId] = useState();

  //
  const [indicatorsList, setIndicatorsList] = useState([]);

  const init = async () => {
    setLoading(true);
    try {
      // -- FILTERS --
      const periodListResponse = await request('ccadmin/api/v1/indicators/periods');
      const directionsListResponse = await request('ccadmin/api/v1/indicators/directions');
      const aimsListResponse = await request('ccadmin/api/v1/indicators/aims');
      // const yearsListResponse = await request("/indicators/years?periodId=1");

      setPeriodList(
        formatOptions(periodListResponse, {
          label: 'range',
          value: 'id',
        })
      );

      setDirectionsList(formatOptions(directionsListResponse));
      setAimsList(formatOptions(aimsListResponse));

      // setAimsList;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // indicators
    handleIndicatorsFetch();
  };

  // Период
  const handlePeriod = ({ period: id }) => setPeriodId(id);

  // Направления
  const handleDirection = ({ direction: id }) => setDirectionId(id);

  // Цели
  const handleAim = ({ aim: id }) => setAimId(id);

  //
  const handleIndicatorsFetch = async () => {
    setLoading(true);
    const indicatorsList = await getIndicators();
    setIndicatorsList(formatIndicators(indicatorsList));

    setLoading(false);
    // setTimeout(async () => {
    //   const indicatorsList = await getIndicators();
    //   setLoading(false);
    //   setIndicatorsList(formatIndicators(indicatorsList));
    // }, 0);
  };

  const getIndicators = () => {
    const params = {
      periodId,
      directionId,
      aimId,
    };
    const queryString = Object.keys(params)
      .map((key) => key + '=' + (params[key] ?? ''))
      .join('&');

    return request(`ccadmin/api/v1/indicators?${queryString}`);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    // -- FILTERS --
    // filter select options
    periodList,
    directionsList,
    aimsList,
    //
    loading,
    indicatorsList,
    //
    periodId,
    directionId,
    aimId,
    // handlers
    handlePeriod,
    handleDirection,
    handleAim,
    handleIndicatorsFetch,
  };

  return <ArmContext.Provider value={value}>{children}</ArmContext.Provider>;
}

export { ArmProvider, useArm };

const formatIndicators = (arr = []) =>
  arr.map((item) => ({
    ...item,
    planFacts: item?.planFacts.map((p) => ({
      ...p,
      category: p.year,
    })),
  }));

const formatOptions = (
  arr = [],
  keys = {
    value: 'id',
    label: 'nameRu',
  }
) =>
  arr.map((i) => ({
    label: i[keys.label || 'id'],
    value: i[keys.value],
  }));
