import React, { Component } from 'react';

import BuisnessMap from './BuisnessMap';
import Currency from './Currency';
import ForeignTrade from './ForeignTrade';
import GosUslugy from './GosUslugy';
import MGZ from './MGZ';
import ParticipantCategory from './ParticipantCategory';
import ProductStructure from './ProductStructure';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';
import Foodbasket from '../../pages/Social/FoodBasket';

const NAME_COMP = {
  'Мониторинг государственных закупок': <MGZ />,
  'Курсы валют': <Currency />,
  'Внешняя торговля': <ForeignTrade />,
  'Товарная структура': <ProductStructure />,
  'Категория участников ВЭД': <ParticipantCategory />,
  'Мониторинг социальных цен на продукты питания': <Foodbasket />,
  'Карта бизнеса': <BuisnessMap />,
  'Государственные услуги': <GosUslugy />,
};

class Economic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: Object.keys(NAME_COMP)[0],
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { subtabs } = this.props;
    return (
      <MainWrapper>
        <CaseMainTitle>Экономика</CaseMainTitle>

        <RadioGroup
          onChange={this.radioChange}
          value={this.state.radio}
          list={subtabs.map((i) => ({
            key: i.name,
            value: i.name,
          }))}
        />

        {Object.keys(NAME_COMP).map((nav) => {
          if (nav === this.state.radio) return NAME_COMP[nav];
          else return null;
        })}
      </MainWrapper>
    );
  }
}

export default Economic;
