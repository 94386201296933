import React from 'react';

const Foodbasket = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/foodbasket"
      title="Foodbasket"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Foodbasket;
