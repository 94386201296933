import React from 'react';

const DtpMap = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/dtp-map"
      title="DtpMap"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default DtpMap;
