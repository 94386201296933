import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Row, Select } from 'antd';
import moment from 'moment';

import styles from './filters.module.scss';

const { RangePicker } = DatePicker;

const Filters = ({ filters, handleFilter }) => {
  const [filtersData, setFiltersData] = useState({ checkbox: false });

  const setDate = ([startDate, endDate]) => {
    let newFilter = { ...filtersData, startDate, endDate };
    setFiltersData(newFilter);
  };

  const handleChangeStatus = (value) => {
    let newFilter = { ...filtersData, status: value };
    if (value.length === 0) delete newFilter.status;
    setFiltersData(newFilter);
  };

  const handleChangeSuppliers = (value) => {
    let newFilter = { ...filtersData, suppliers: value };
    if (value.length === 0) delete newFilter.suppliers;
    setFiltersData(newFilter);
  };

  const handleChangeWork = (value) => {
    let newFilter = {
      ...filtersData,
      work: filters.works.filter((o1) => value.some((o2) => o1.category === o2)),
    };
    if (value.length === 0) delete newFilter.work;
    setFiltersData(newFilter);
  };

  const handleChangeCustomer = (value) => {
    let newFilter = { ...filtersData, customer: value };
    if (value.length === 0) delete newFilter.customer;
    setFiltersData(newFilter);
  };

  const handleChangeResult = (value) => {
    let newFilter = {
      ...filtersData,
      result: filters.results.filter((o1) => value.some((o2) => o1.result === o2)),
    };
    if (value.length === 0) delete newFilter.result;
    setFiltersData(newFilter);
  };

  const handleChangeCheckbox = (value) => {
    setFiltersData({ ...filtersData, checkbox: value.target.checked });
  };

  useEffect(() => {
    handleFilter(filtersData);
  }, [filtersData, handleFilter]);

  return (
    <Row className={styles.row} type="flex" gutter={20}>
      <Col>
        <h4>Период</h4>
        <DatePicker.RangePicker
          className={styles.picker}
          onChange={setDate}
          separator="—"
          value={[
            filtersData.startDate || moment().startOf('year'),
            filtersData.endDate || moment().endOf('year'),
          ]}
          showTime={{
            secondStep: 60,
            minuteStep: 15,
          }}
          allowClear={false}
        />
      </Col>
      <Col span={2}>
        <h4>Статус</h4>
        <Select
          className={styles.select}
          onChange={handleChangeStatus}
          mode="multiple"
          showSearch
          placeholder="Выберите"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filters.statuses.map((e) => (
            <Select.Option key={e} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={2}>
        <h4>Категория работ</h4>
        <Select
          className={styles.select}
          onChange={handleChangeWork}
          mode="multiple"
          showSearch
          placeholder="Выберите"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filters.works.map((e) => (
            <Select.Option key={e.id} value={e.category}>
              {e.category}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={2}>
        <h4>Подрядчик</h4>
        <Select
          className={styles.select}
          onChange={handleChangeSuppliers}
          mode="multiple"
          showSearch
          placeholder="Выберите"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filters.suppliers.map((el) => (
            <Select.Option key={el} value={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={2}>
        <h4>Заказчик</h4>
        <Select
          className={styles.select}
          onChange={handleChangeCustomer}
          mode="multiple"
          showSearch
          placeholder="Выберите"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filters.customers.map((e) => (
            <Select.Option key={e} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={3}>
        <h4>Результат экспертизы</h4>
        <Select
          className={styles.select}
          onChange={handleChangeResult}
          mode="multiple"
          showSearch
          placeholder="Выберите"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filters.results.map((e) => (
            <Select.Option key={e.id} value={e.result}>
              {e.result}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <div className={styles.container}>
          <Checkbox className={styles.checkbox} onChange={handleChangeCheckbox} />
          <h4 className={styles.title}>Архивные ремонты дорог</h4>
        </div>
      </Col>
    </Row>
  );
};

export default Filters;
