import React from 'react';
import { Map, Polyline, YMaps } from 'react-yandex-maps';
import moment from 'moment';

import styles from './RoadRepairMap.module.scss';

const balloonContent = (value) => {
  return (
    '<b>№ договора:</b> ' +
    value.mgzRoadwayDTO?.contractNumber +
    '<br/>' +
    '<b>№ п/п:</b> ' +
    value.mgzRoadwayDTO?.planNumber +
    '<br/>' +
    '<b>Дата подписания:</b> ' +
    moment(value.mgzRoadwayDTO?.signDate).format('YYYY-MM-DD HH:MM') +
    '<br/>' +
    '<b>Участок:</b> ' +
    value.mgzProcessedRoadwayDTO?.area +
    '<br/>' +
    '<b>Статус:</b> ' +
    value.mgzRoadwayDTO?.status +
    '<br/>' +
    '<b>Срок выполнения ремонта:</b> ' +
    moment(value.mgzProcessedRoadwayDTO?.startDate).format('YYYY-MM-DD') +
    ' - ' +
    moment(value.mgzProcessedRoadwayDTO?.endDate).format('YYYY-MM-DD') +
    '<br/>' +
    '<b>Гарантийный срок:</b> ' +
    value.mgzProcessedRoadwayDTO?.warrantyEndDate
  );
};

const POLYLINE_BASE_OPTIONS = {
  balloonCloseButton: true,
  strokeWidth: 4,
  strokeOpacity: 0.5,
};

const RoadRepairMap = ({ works = [], contracts = [] }) => {
  return (
    <>
      <YMaps>
        <Map
          state={{
            center: [49.807755, 73.088502],
            zoom: 10,
            behaviors: ['default', 'scrollZoom'],
          }}
          width="100%"
          height="350px"
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        >
          {contracts.map((element, index) => {
            const coordsContracts = element.mgzProcessedRoadwayDTO?.coordinates.map((e) => [
              e.x,
              e.y,
            ]);
            const options = {
              ...POLYLINE_BASE_OPTIONS,
              strokeColor: works.find(
                (e) => e.category === element.mgzProcessedRoadwayDTO?.workCategory[0].category
              ).color,
            };
            return (
              <Polyline
                key={index}
                geometry={coordsContracts}
                properties={{
                  hintContent: balloonContent(element),
                }}
                options={options}
              />
            );
          })}
        </Map>
      </YMaps>
      {works.map((e, index) => (
        <div className={styles.container} key={index}>
          <span className={`dot ${styles.span}`} style={{ backgroundColor: `${e.color}` }}></span>
          {e.category}
        </div>
      ))}
    </>
  );
};

export default RoadRepairMap;
