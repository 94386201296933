import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import styles from './indicators.module.scss';

const columns = [
  {
    title: 'Средняя стоимость м² ремонта дорог (тг)',
    value: 'averageCostRoad',
    key: 'averageCostRoad',
  },
  {
    title: 'Сумма ремонтов (тг)',
    value: 'sumCostRoad',
    key: 'sumCostRoad',
  },
  {
    title: 'Суммарная протяженность ремонта (км)',
    value: 'sumLength',
    key: 'sumLength',
  },
  {
    title: 'Средний остаток гарантийного срока (мес)',
    value: 'sumWarranty',
    key: 'sumWarranty',
  },
];

const initialIndicatorValues = {
  averageCostRoad: 0,
  sumCostRoad: 0,
  sumLength: 0,
  sumWarranty: 0,
};

const Indicators = ({ contracts }) => {
  const [indicatorValues, setIndicatorValues] = useState(initialIndicatorValues);

  const generateColumn = ({ title, value, key }) => {
    return (
      <Col className={styles.col} key={key} span={6}>
        <h3 className={styles.title}>{title}</h3>
        <h3 className={styles.subtitle}>{indicatorValues[value]}</h3>
      </Col>
    );
  };

  useEffect(() => {
    if (contracts.length > 0) {
      const sum1 = _.sumBy(contracts, 'mgzRoadwayDTO.amount');
      const sum2 = _.sumBy(contracts, 'mgzProcessedRoadwayDTO.square');
      let averageCostRoad = 0;
      if (sum2 !== 0) {
        averageCostRoad = sum1 / sum2;
      }

      let sumCostRoad = _.sumBy(contracts, 'mgzRoadwayDTO.amount');

      let sumLength =
        Math.round((_.sumBy(contracts, 'mgzProcessedRoadwayDTO.length') / 1000) * 100) / 100;

      let warrantySum = 0;
      contracts.forEach((i) => {
        let diff = moment(i?.mgzProcessedRoadwayDTO?.warrantyEndDate, 'YYYY-MM-DD').diff(
          moment(),
          'months',
          true
        );
        warrantySum += parseFloat(diff);
      });

      let sumWarranty = (warrantySum / contracts.length).toFixed(1);

      setIndicatorValues({
        averageCostRoad,
        sumCostRoad,
        sumLength,
        sumWarranty,
      });
    } else {
      setIndicatorValues(initialIndicatorValues);
    }
  }, [contracts]);

  return <Row className={styles.container}>{columns.map((column) => generateColumn(column))}</Row>;
};

export default Indicators;
