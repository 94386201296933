import React from 'react';

const Ecology1 = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/ecology"
      title="ecology"
      style={{ border: 'none' }}
      width="100%"
      height="149%"
    />
  );
};

export default Ecology1;
