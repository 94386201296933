import { isJson } from './helpers';

import { API_ROOT } from '../config';
import { logoutUser } from '../redux/auth';
import store from '../store';

const codeMessage = {
  401: 'Пользователь не авторизован',
  403: 'Пользователь авторизован, но доступ запрещен.',
  404: 'Запрос делается для записи, которая не существует',
  500: 'На сервере произошла ошибка.',
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const errortext = codeMessage[response.status] || response.statusText;

  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

const parseText = (text) => {
  return text.length ? (isJson(text) ? JSON.parse(text) : text) : {};
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, option) {
  const options = {
    ...option,
  };

  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PATCH' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData) && !(newOptions.body instanceof URLSearchParams)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  return fetch(`${API_ROOT}${url}`, newOptions)
    .then(checkStatus)
    .then((response) => {
      // response does not always has json, using .json will report an error.
      return response.text();
    })
    .then((text) => parseText(text))
    .catch(async (e) => {
      const { name: status, response } = e;

      const isLoginUrl = response ? response.url.includes('/signin') : '';
      const responseText = await response.text();
      const responseJSON = parseText(responseText);
      const error = { status, ...responseJSON };

      if (status === 401 && !isLoginUrl) {
        // @HACK
        /* eslint-disable no-underscore-dangle */
        store.dispatch(logoutUser());
      } else if (status === 403) {
        // environment should not be used
        // TODO: if user has no access
      } else if (status >= 404 && status < 422 && status !== 418) {
        // TODO: if not found
      } else if (!isLoginUrl) {
        // TODO: the rest
      }

      return Promise.reject(error);
    });
}
