import { login, logout } from '../services/api';
import { setAuthority } from '../utils/authority';

// login user
export const LOGIN = 'sckrg/auth/LOGIN';
export const loginUser = (values) => async (dispatch) => {
  try {
    const user = await login(values);
    //await ccadminLogin(values);
    setAuthority('AUTHED');
    dispatch({ type: LOGIN, payload: user });
    window.localStorage.setItem('user_params', JSON.stringify(user));
    setTimeout(() => {
      window.location.reload();
    }, 10);
  } catch (e) {
    alert('Неверное имя пользователя или пароль');
  }
};

// logout user
export const LOGOUT = 'sckrg/auth/LOGOUT';
export const logoutUser = () => async (dispatch) => {
  await logout();
  //await ccadminLogout();
  setAuthority('GUEST');
  dispatch({ type: LOGOUT });
  window.localStorage.removeItem('user_params');
};
