import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 40 }}>
      <h2>Страница в разработке</h2>
      {/* <h1 align="center">404</h1> */}

      <img src="./images/construction.jpg" alt="Страница в разработке" style={{ width: 400 }} />

      <Link to="/" style={{ display: 'block', textAlign: 'center' }}>
        На главную
      </Link>
    </div>
  );
};

export default NotFound;
