import React, { Component } from 'react';

import Crime from './Crime';
import Dtp from './Dtp';
import DtpMap from './DtpMap';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';

const NAME_COMP = {
  'Криминогенная обстановка': <Crime />,
  ДТП: <Dtp />,
  'Карта опасных участков': <DtpMap />,
};

class Safety extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: Object.keys(NAME_COMP)[0],
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { subtabs } = this.props;
    return (
      <MainWrapper>
        <CaseMainTitle>Общественная безопасность</CaseMainTitle>
        <RadioGroup
          onChange={this.radioChange}
          value={this.state.radio}
          list={subtabs.map((i) => ({
            key: i.name,
            value: i.name,
          }))}
        />
        {Object.keys(NAME_COMP).map((nav) => {
          if (nav === this.state.radio) return NAME_COMP[nav];
          else return null;
        })}
      </MainWrapper>
    );
  }
}

export default Safety;
