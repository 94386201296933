import React from 'react';

const KnowledgeQuality = () => {
  return (
    <iframe
      src="https://app.powerbi.com/view?r=eyJrIjoiNTRkOTYzZDItZDVhYi00N
      TdlLTliYTYtMmI4MzQyNDdmZDAzIiwidCI6IjA0MzZjMjkxLWNlYjktNDUyNS04YTg5LWI4MTVjZjYxZjhlYiIsImMiOjl9"
      title="Knowledge Quality"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default KnowledgeQuality;
