import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export default class LoadingSpinner extends Component {
  static defaultProps = {
    size: 32,
    borderWidth: 4,
  };

  render() {
    let { loading, children, size, borderWidth, className, style } = this.props;
    return loading ? (
      <div className={classNames(styles.LoadingSpinnerWrapper, className)} style={style}>
        <div className={styles.LoadingSpinner} style={{ width: size, height: size, borderWidth }} />
      </div>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );
  }
}
