import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getAuthority } from '../utils/authority';

export const withAuthorized = (WrappedComponent) => {
  class Authorized extends Component {
    render() {
      const authority = getAuthority();
      if (!authority.includes('AUTHED')) return <Redirect to="/signin" />;
      return <WrappedComponent {...this.props} />;
    }
  }
  return Authorized;
};
