import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Popover } from 'antd';
import moment from 'moment';

import TopNavigation from './TopNavigation';

import { logoutUser } from '../redux/auth';

import styles from './styles.module.scss';

class HeaderLayout extends Component {
  _isMounted = false;

  state = {
    selectedCity: 343,
    notifications: [],
  };

  async componentDidMount() {
    this._isMounted = true;
    let { selectedCity } = this.state;
    this.loadNotifications(selectedCity);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadNotifications = async (value) => {
    try {
      const notifRes = await fetch('https://cc.krgsc.kz/api/data/darmen');
      const notifData = await notifRes.json();
      const { notifications } = notifData.data.model[0];
      if (this._isMounted) {
        this.setState({
          selectedCity: value,
          notifications,
        });
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          selectedCity: value,
          notifications: [],
        });
      }
    }
  };

  render() {
    const { notifications } = this.state;
    const { logoutUser } = this.props;
    let user = window.localStorage.getItem('user_params');
    if (user) {
      user = JSON.parse(user);
    }
    return (
      <header className={styles.header}>
        <div className="logo">
          <Button icon={'menu'} style={{ marginRight: 6 }}>
            Меню
          </Button>
          <a className={styles.logo} href="https://cc.krgsc.kz/">
            <img src="./images/gerb.svg" alt="logo" width="40px" />
            <h3>Карагандинская область</h3>
          </a>
        </div>
        <TopNavigation {...this.props} />
        <div>
          <Popover
            placement="bottomRight"
            title={<span>Уведомления</span>}
            content={
              <div className={styles.header__notifications}>
                {notifications.map((n, index) => (
                  <div key={index} className="notification">
                    <img
                      src="./images/mchs.png"
                      width={60}
                      height={60}
                      alt="МЧС"
                      style={{
                        float: 'left',
                        marginRight: 10,
                        marginBottom: 6,
                      }}
                    />
                    <div>
                      <div className="location">{n.location_name}</div>
                      <div className="date">
                        {moment(n.created_at).format('DD.MM.YYYY HH:mm:ss')}
                      </div>
                      <div className="title">{n.title}</div>
                      <div className="text">{n.text}</div>
                    </div>
                  </div>
                ))}
              </div>
            }
            trigger="click"
          >
            <Button icon="bell" style={{ marginRight: 50 }} />
          </Popover>
          <Button onClick={logoutUser} style={{ marginRight: 6 }}>
            Выйти
          </Button>
        </div>
      </header>
    );
  }
}

export default withRouter(connect(null, { logoutUser })(HeaderLayout));
