import React, { Component } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import { CHART_LOCALE } from '../../../components/common/Constants';

class ForecastLineChart extends Component {
  state = {};

  componentDidUpdate() {
    if (JSON.stringify(this.props) !== JSON.stringify(this.state)) {
      this.setState(this.props, () => this.initChart());
    }
  }

  initChart = () => {
    const { labels, menData, womenData, all, dispersionUp, dispersionDown } = this.state;
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    chart.language.locale = CHART_LOCALE;

    chart.paddingTop = 10;
    let arr = labels.filter((e) => e > 2014);
    let data = arr.map((year, index) => {
      let obj = {
        date: year,
        men: menData[index],
        women: womenData[index],
        all: all[index],
      };
      if (dispersionDown[index]) obj.dispersionDown = dispersionDown[index];
      if (dispersionUp[index]) obj.dispersionUp = dispersionUp[index];

      return obj;
    });
    chart.data = data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.grid.template.stroke = am4core.color('#fff');

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    // valueAxis.title.text = "Количество людей";

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = 'date';
    series.dataFields.valueY = 'men';
    series.name = 'Мужчины';
    series.stroke = am4core.color('#2AA6FF');
    series.fill = am4core.color('#2AA6FF');
    series.tooltipText = '{name}: [bold]{valueY}[/]';
    series.strokeWidth = 3;
    let circleBulletMan = series.bullets.push(new am4charts.CircleBullet());
    circleBulletMan.circle.radius = 4;

    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.categoryX = 'date';
    series2.dataFields.valueY = 'women';
    series2.name = 'Женщины';
    series2.stroke = am4core.color('#E94DD0');
    series2.fill = am4core.color('#E94DD0');
    series2.tooltipText = '{name}: [bold]{valueY}[/]';
    series2.strokeWidth = 3;
    let circleBulletWoman = series2.bullets.push(new am4charts.CircleBullet());
    circleBulletWoman.circle.radius = 4;

    let seriesAll = chart.series.push(new am4charts.LineSeries());
    seriesAll.stroke = am4core.color('#F9CF62');
    seriesAll.fill = am4core.color('#F9CF62');
    let rectangleBullet = seriesAll.bullets.push(new am4charts.Bullet());
    let square = rectangleBullet.createChild(am4core.Rectangle);
    square.width = 5;
    square.height = 7;
    square.horizontalCenter = 'middle';
    square.verticalCenter = 'middle';
    seriesAll.dataFields.categoryX = 'date';
    seriesAll.dataFields.valueY = 'all';
    seriesAll.name = 'Все';
    seriesAll.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesAll.strokeWidth = 3;
    seriesAll.fillOpacity = 0.5;

    var fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    seriesAll.segments.template.fillModifier = fillModifier;

    let disUp = chart.series.push(new am4charts.LineSeries());
    disUp.dataFields.categoryX = 'date';
    disUp.dataFields.valueY = 'dispersionUp';
    disUp.name = 'Прогнозный интервал (Оптимистичный сценарий)';
    disUp.stroke = am4core.color('#32cd66');
    disUp.fill = am4core.color('#32cd66');
    disUp.tooltipText = '{name}: [bold]{valueY}[/]';
    disUp.strokeWidth = 3;
    let circleBulletPositive = disUp.bullets.push(new am4charts.CircleBullet());
    circleBulletPositive.circle.radius = 4;

    let disDown = chart.series.push(new am4charts.LineSeries());
    disDown.dataFields.categoryX = 'date';
    disDown.dataFields.valueY = 'dispersionDown';
    disDown.name = 'Прогнозный интервал (Пессимистичный сценарий)';
    disDown.stroke = am4core.color('#FF7324');
    disDown.fill = am4core.color('#FF7324');
    disDown.tooltipText = '{name}: [bold]{valueY}[/]';
    disDown.strokeWidth = 3;
    let circleBulletNegative = disDown.bullets.push(new am4charts.CircleBullet());
    circleBulletNegative.circle.radius = 4;

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.itemContainers.template.marginBottom = 20;
    this.chart = chart;
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {
    return (
      <div id="chartdiv" className={'forecast-chart'} style={{ width: '100%', height: '66vh' }} />
    );
  }
}
export default ForecastLineChart;
