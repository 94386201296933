import React, { Component } from 'react';
import { Button, Modal, Row, Table } from 'antd';
import uniq from 'lodash/uniq';
import moment from 'moment';

import {
  categoryTable,
  colors,
  columns,
  fields,
  pagination,
  pieFields,
  subCategoryTable,
} from './utils';

import ExportExcel from '../../../components/ExportExcel';
import { ChartCard } from '../../../components/newDesign/ChartCard/ChartCard';
import { DatePickerR } from '../../../components/newDesign/DatePickerR';
import { FilterItem } from '../../../components/newDesign/FilterItem/FilterItem';
import { FilterWrapper } from '../../../components/newDesign/FilterWrapper/FilterWrapper';
import { MainWrapper } from '../../../components/newDesign/MainWrapper/MainWrapper';
import { RowWrapper } from '../../../components/newDesign/RowWrapper/RowWrapper';
import { SelectList } from '../../../components/newDesign/SelectList/SelectList';
import PieChart from '../../../components/visualizations/PieChart';
import cubejs from '../../../cube';
const cubejsApi = cubejs({ appId: 1 });

class NewAppeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: [moment().utc(6).startOf('month').startOf('day'), moment().utc(6).endOf('day')],
      selectedCategories: undefined,
      pieData: [],
      modalVisible: false,
      tableData: [],
      tableHeader: categoryTable,
      modalData: [],
      selectedRegions: [],
      selectedDistricts: [],
      loading: false,
      disabledRegion: false,
      disabledDistrict: false,
      regions: [],
      districts: [],
      categories: [],
    };
  }

  componentDidMount() {
    let role = JSON.parse(localStorage.getItem('user_params'));
    this.loadDictionaries();
    if (role.roles[0].region.length) {
      this.checkForRegion();
    } else {
      this.getData();
    }
  }

  loadDictionaries = async () => {
    const { range } = this.state;
    const regions = await cubejsApi.load({
      dimensions: ['AppealsRegion.nameRu', 'AppealsRegion.parentId', 'AppealsRegion.id'],
      filters: [
        {
          member: 'AppealsRegion.parentId',
          operator: 'notSet',
        },
      ],
    });

    const districts = await cubejsApi.load({
      dimensions: ['AppealsRegion.nameRu', 'AppealsRegion.parentId', 'AppealsRegion.id'],
      filters: [
        {
          member: 'AppealsRegion.parentId',
          operator: 'set',
        },
      ],
    });

    const categories = await cubejsApi.load({
      dimensions: ['Appeals.category', 'Appeals.region', 'Appeals.district'],
      filters: [
        {
          member: 'Appeals.submissionDate',
          operator: 'inDateRange',
          values: range,
        },
      ],
    });

    this.setState({
      regions: regions.rawData(),
      districts: districts.rawData(),
      categories: categories.rawData().filter((e) => e['Appeals.category']),
    });
  };

  getData = () => {
    this.setState({ loading: true });
    this.getPieData();
    this.getTableData();
    this.setState({ loading: false });
  };

  getPieData = async () => {
    const { range, selectedCategories, selectedRegions, selectedDistricts } = this.state;
    const query = {
      measures: ['Appeals.appealsNum'],
      timeDimensions: [{ dimension: 'Appeals.submissionDate' }],
      dimensions: ['Appeals.appealType'],
      filters: [],
      renewQuery: true,
    };
    if (range) {
      query.filters.push({
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: range,
      });
    }
    if (selectedCategories) {
      query.filters.push({
        member: 'Appeals.category',
        operator: 'equals',
        values: [selectedCategories],
      });
    }
    if (selectedRegions?.length) {
      query.filters.push({
        member: 'Appeals.region',
        operator: 'equals',
        values: selectedRegions,
      });
    }
    if (selectedDistricts?.length) {
      query.filters.push({
        member: 'Appeals.district',
        operator: 'equals',
        values: selectedDistricts,
      });
    }
    const resultSet = await cubejsApi.load(query);

    let pieData = resultSet.rawData().map((e) => ({
      category: e['Appeals.appealType'],
      value: +e['Appeals.appealsNum'],
    }));

    const total = pieData.reduce((acc, current) => acc + current.value, 0);
    pieData = pieData.map((e) => ({
      ...e,
      percent: ((e.value * 100) / total).toFixed(),
      color: colors.find((i) => i.category === e.category).color,
    }));

    this.setState({ pieData });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  getTableData = async () => {
    const { range, selectedCategories, selectedDistricts, selectedRegions } = this.state;
    const query = {
      measures: [
        'Appeals.appealsNum',
        'Appeals.appealsCategory',
        'Appeals.appealsIncident',
        'Appeals.appealsInfo',
      ],
      timeDimensions: [{ dimension: 'Appeals.submissionDate' }],
      dimensions: ['Appeals.category'],
      filters: [],
      renewQuery: true,
    };
    if (range) {
      query.filters.push({
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: range,
      });
    }
    let tableHeader = categoryTable;
    if (selectedCategories) {
      query.filters.push({
        member: 'Appeals.category',
        operator: 'equals',
        values: [selectedCategories],
      });
      query.dimensions.push('Appeals.subCategory');
      tableHeader = subCategoryTable;
    }
    if (selectedRegions?.length) {
      query.filters.push({
        member: 'Appeals.region',
        operator: 'equals',
        values: selectedRegions,
      });
    }
    if (selectedDistricts?.length) {
      query.filters.push({
        member: 'Appeals.district',
        operator: 'equals',
        values: selectedDistricts,
      });
    }
    const resultSet = await cubejsApi.load(query);
    let tableData = resultSet
      .rawData()
      .map((e, index) => ({
        key: ++index,
        category: e['Appeals.category'],
        countCategory: +e['Appeals.appealsNum'],
        countAppeals: +e['Appeals.appealsCategory'],
        countIncident: +e['Appeals.appealsIncident'],
        countInfo: +e['Appeals.appealsInfo'],
        subCategory: e['Appeals.subCategory'],
      }))
      .filter((e) => e.category);

    this.setState({ tableData, tableHeader });
  };

  rowClicked = (val) => {
    const { selectedRegions, selectedDistricts, selectedCategories, range } = this.state;
    if (selectedCategories) {
      let request = {
        filters: [
          {
            dimension: 'Appeals.category',
            operator: 'equals',
            values: [val.category],
          },
          {
            dimension: 'Appeals.subCategory',
            operator: 'equals',
            values: [val.subCategory],
          },
        ],
        dimensions: [
          'Appeals.appealNum',
          'Appeals.fio',
          'Appeals.status',
          'Appeals.category',
          'Appeals.subCategory',
          'Appeals.executor',
          'Appeals.status',
          'Appeals.appealText',
          'Appeals.expired',
          'Appeals.submissionDate',
          'Appeals.submissionTime',
          'Appeals.appealType',
        ],
        renewQuery: true,
      };
      if (range) {
        request.filters.push({
          member: 'Appeals.submissionDate',
          operator: 'inDateRange',
          values: range,
        });
      }

      if (selectedRegions?.length) {
        request.filters.push({
          member: 'Appeals.region',
          operator: 'equals',
          values: selectedRegions,
        });
      }

      if (selectedDistricts?.length) {
        request.filters.push({
          member: 'Appeals.district',
          operator: 'equals',
          values: selectedDistricts,
        });
      }
      cubejsApi.load(request).then((r) => {
        const indexedModalData = r.rawData().map((el, id) => {
          return { ...el, key: id + 1 };
        });
        this.setState({
          modalData: indexedModalData.map((el) => ({
            ...el,
            'Appeals.submissionDate': moment(el['Appeals.submissionDate']).format('YYYY-MM-DD'),
          })),
          modalVisible: true,
          subCategory: val.subCategory,
        });
      });
    } else
      this.setState(
        {
          selectedCategories: val.category,
          subCategory: val.subCategory,
        },
        () => this.getData()
      );
  };

  handleRange = (val) => {
    this.setState({ range: val }, () => {
      this.loadDictionaries();
      this.getData();
    });
  };

  resetFilter = () => {
    const { selectedRegions } = this.state;
    let forFilterRegion = JSON.parse(localStorage.getItem('user_params')).roles[0].region.length
      ? selectedRegions
      : undefined;
    this.setState(
      {
        selectedCategories: undefined,
        selectedRegions: forFilterRegion,
        selectedDistricts: [],
      },
      () => this.getData()
    );
  };

  checkForRegion = async () => {
    let role = JSON.parse(localStorage.getItem('user_params'));
    if (role?.roles?.[0]?.region?.length) {
      const selectedRegions = role.roles[0].region
        .filter((region) => !region?.parentId)
        .map((region) => region.regionName);

      /*
       * Эта часть кода была создана специально
       * для учетных записей user-krg-bkh и
       * user-krg-kzb. Данный кейс является
       * уникальным, поэтому логика
       * прописывалась исключительно под них.
       */

      let krgDistrict = [];

      if (role.userId === 44) {
        krgDistrict = [role.roles[0].region[0].child[0].regionName];
      } else if (role.userId === 45) {
        krgDistrict = [role.roles[0].region[0].child[1].regionName];
      }

      this.setState(
        {
          selectedRegions,
          selectedDistricts: krgDistrict,
          disabledRegion: true,
          disabledDistrict: krgDistrict.length || false,
        },
        () => this.getData()
      );
    }
  };

  filteredDistricts = () => {
    const { districts, regions, selectedRegions } = this.state;
    return districts
      .filter((district) => {
        if (!selectedRegions?.length) {
          return true;
        }
        const ids = [];
        selectedRegions.forEach((reg) => {
          const regionObj = regions.find(
            (it) => reg.toLowerCase() === it['AppealsRegion.nameRu'].toLowerCase()
          );
          if (regionObj) {
            ids.push(regionObj?.['AppealsRegion.id']);
          }
        });
        return ids.includes(district['AppealsRegion.parentId']);
      })
      .map((e) => e['AppealsRegion.nameRu']);
  };

  filteredCategories = () => {
    const { categories, regions, districts, selectedRegions, selectedDistricts } = this.state;
    const newCategories = categories
      ?.filter((category) => {
        if (!selectedRegions?.length) {
          return true;
        }
        if (!selectedDistricts?.length) {
          const names = [];
          selectedRegions.forEach((region) => {
            const regionObj = regions.find((it) => region === it['AppealsRegion.nameRu']);
            names.push(regionObj?.['AppealsRegion.nameRu']);
          });
          return names.includes(category['Appeals.region']);
        }
        const districtNames = [];
        const regionNames = [];
        selectedDistricts.forEach((district) => {
          const districtObj = districts.find((it) => district === it['AppealsRegion.nameRu']);
          districtNames.push(districtObj?.['AppealsRegion.nameRu']);
        });
        selectedRegions.forEach((region) => {
          const regionObj = regions.find((it) => region === it['AppealsRegion.nameRu']);
          regionNames.push(regionObj?.['AppealsRegion.nameRu']);
        });
        return (
          districtNames.includes(category['Appeals.district']) &&
          regionNames.includes(category['Appeals.region'])
        );
      })
      .map((e) => e['Appeals.category']);
    return uniq(newCategories);
  };

  applyFilters = (filter, values) => {
    const { regions, districts, selectedRegions } = this.state;
    const filters = {};
    if (filter === 'selectedRegions' && !values.length) {
      filters.selectedCategories = undefined;
      filters.selectedDistricts = [];
    }
    if (filter === 'selectedDistricts' && selectedRegions.length === 0) {
      const districtObject = districts.find(
        (district) => district['AppealsRegion.nameRu'] === values[0]
      );
      const regionObject = regions.find(
        (region) => region['AppealsRegion.id'] === districtObject['AppealsRegion.parentId']
      );
      filters.selectedRegions = [regionObject['AppealsRegion.nameRu']];
      filters.selectedCategories = undefined;
    }
    this.setState(
      {
        [filter]: values,
        ...filters,
      },
      () => this.getData()
    );
  };
  render() {
    const {
      tableData,
      selectedRegions,
      selectedDistricts,
      disabledRegion,
      disabledDistrict,
      selectedCategories,
      subCategory,
      range,
      pieData,
      loading,
      tableHeader,
      regions,
      modalData,
      modalVisible,
    } = this.state;
    let count = tableData.reduce((a, b) => a + b.countCategory, 0);
    if (selectedCategories)
      count = tableData
        .filter((e) => e.category === selectedCategories)
        .reduce((a, b) => a + b.countCategory, 0);
    return (
      <MainWrapper>
        <FilterWrapper>
          <FilterItem>
            <SelectList
              label={'Исполнительный орган:'}
              onChange={(value) => this.applyFilters('selectedCategories', value)}
              value={selectedCategories}
              size="small"
              allowClear
              list={this.filteredCategories()}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={'Регион:'}
              onChange={(value) => this.applyFilters('selectedRegions', value)}
              disabled={disabledRegion}
              value={selectedRegions}
              size="small"
              mode="multiple"
              allowClear
              list={regions.map((e) => e['AppealsRegion.nameRu'])}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={'Населенный пункт:'}
              onChange={(value) => this.applyFilters('selectedDistricts', value)}
              value={selectedDistricts}
              disabled={disabledDistrict}
              size="small"
              mode="multiple"
              allowClear
              list={this.filteredDistricts()}
            />
          </FilterItem>
          <FilterItem>
            <DatePickerR
              label={'Период:'}
              onChange={this.handleRange}
              showTime={{
                secondStep: 60,
                minuteStep: 15,
              }}
              allowClear={false}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <Button
              disabled={!selectedCategories && !selectedRegions && !selectedDistricts}
              onClick={this.resetFilter}
            >
              Сбросить
            </Button>
          </FilterItem>
          <FilterItem action>
            <ExportExcel
              filename={`Отчет по обращениям граждан ${
                selectedCategories !== undefined ? '(' + selectedCategories + ')' : ''
              } за ${range[0].format('YYYY-MM-DD HH:mm:ss')} – ${range[1].format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
              data={tableData}
              fields={pieFields}
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title="Статистические данные поступивших обращений по направлениям">
            <PieChart
              id="pieChartID"
              showTotal
              totalFontSize={16}
              loading={loading}
              data={pieData}
              disableMovement
              height="500px"
            />
            {selectedCategories ? (
              selectedCategories.length === 1 || !Array.isArray(selectedCategories) ? (
                <h4 style={{ margin: '10px' }}>
                  Количество обращений по категории {selectedCategories}: {count}
                </h4>
              ) : null
            ) : null}
          </ChartCard>
          <ChartCard title="Таблица">
            <Table
              loading={loading}
              dataSource={tableData}
              onRow={(record) => ({ onClick: () => this.rowClicked(record) })}
              align="left"
              size="small"
              scroll={{ y: 450 }}
              pagination={pagination}
            >
              {tableHeader.map((item, index) => (
                <Table.Column
                  title={item.label}
                  key={index}
                  dataIndex={item.key}
                  width={item.width}
                  sorter={item.sorter}
                  align="center"
                />
              ))}
            </Table>
          </ChartCard>
        </RowWrapper>

        <Modal
          title={
            <Row style={{ paddingRight: 20 }} type="flex" justify="space-between">
              <h5 style={{ maxWidth: '90%' }}>
                Количество обращений по категории
                <span style={{ color: '#5bbbff' }}> {selectedCategories}</span>, подкатегории
                <span style={{ color: '#5bbbff' }}> {subCategory}</span>{' '}
                {`c ${range[0].format('YYYY-MM-DD')} по ${range[1].format('YYYY-MM-DD')}: `}
                {count}
              </h5>
              <ExportExcel
                filename={`Детализация с${moment(range[0]).format('DD/MM/YY')} по ${moment(
                  range[1]
                ).format('DD/MM/YY')}`}
                data={modalData}
                fields={fields}
              />
            </Row>
          }
          visible={modalVisible}
          onCancel={this.hideModal}
          width="96%"
          footer={null}
          bodyStyle={{
            maxHeight: '818px',
            overflowY: 'auto',
          }}
          centered
        >
          <Table
            rowClassName={(record) =>
              record['Appeals.expired'] === 'Просрочено' ? 'row error' : ''
            }
            columns={columns}
            dataSource={modalData}
            size="small"
            scroll={{ y: '60vh', x: 'max-content' }}
            pagination={pagination}
          />
        </Modal>
      </MainWrapper>
    );
  }
}

export default NewAppeals;
