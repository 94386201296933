import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { redirect } from './redux/redirect';
import Router from './router';

class App extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      this.props.history.push(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  render() {
    return (
      <div className="App">
        <Router />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      redirectTo: state.redirect.redirectTo,
    }),
    (dispatch) => ({
      onRedirect: () => dispatch(redirect()),
    })
  )(App)
);
