import React from 'react';

const MGZ = () => {
  return (
    <iframe
      width="100%"
      height="95.3%"
      title="Мониторинг государственных закупок"
      src="https://app.powerbi.com/view?r=eyJrIjoiYTA4MGIyYWMtYzFkNS00ODcz
    	LTk3NTItNjlkMzZmZDFlZTJmIiwidCI6ImZkYTc1ZGJiLTc5MjUtNGM5Ny1hM2NhLTNiZDJmZDU0OWU5MCIsImMiOjl9"
      frameBorder="0"
      allowFullScreen={true}
    />
  );
};

export default MGZ;
