import React, { Component } from 'react';

import HeaderLayout from './HeaderLayout';

import { withAuthorized } from '../hoc/Authorized';

class MainLayout extends Component {
  render() {
    const { component: ComponentLayout, ...rest } = this.props;
    return (
      <div className="page-layout">
        <HeaderLayout {...rest} />
        <main style={{ width: '100%' }}>
          <article className="content" style={{ minHeight: '100%' }}>
            <ComponentLayout {...rest} />
          </article>
        </main>
      </div>
    );
  }
}

export default withAuthorized(MainLayout);
