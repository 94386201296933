import React from 'react';

const OperationalToday = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/operational-summary"
      title="OperationalToday"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default OperationalToday;
