import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

// import { LOGIN, LOGOUT } from "redux/auth";

const REDIRECT = 'sckrg/redirect/REDIRECT';
const LOGIN = 'sckrg/auth/LOGIN';
const LOGOUT = 'sckrg/auth/LOGOUT';

const redirectTo = handleActions(
  {
    [REDIRECT]: { next: (state) => null },
    [LOGIN]: {
      next: (state, { payload }) => {
        const role = payload.roles[0];
        const first_url = payload.homePage;
        if (role.name === 'superadmin' || role.name === 'admin') return '/';
        else return first_url.split('cc.krgsc.kz')[1];
      },
    },
    [LOGOUT]: { next: (state, { payload }) => '/signin' },
  },
  null
);

export default combineReducers({
  redirect: combineReducers({
    redirectTo,
  }),
});
