import React from 'react';

const Monitoring = () => {
  return (
    <iframe
      src="http://bus62.ru/karaganda/index.php"
      title="transport-monitoring"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default Monitoring;
