import React from 'react';

const PublicTransport = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/public-transport"
      title="public-transport"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};

export default PublicTransport;
