import React from 'react';
import ReactExport from 'react-export-excel';
import { Button } from 'antd';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

// export default class ExportExcel extends Component {
//   render() {
//     const { filename, data, fields, style, showPeriod } = this.props;
//     return (
//       <ExcelFile
//         element={
//           <Button type="primary" icon="file-excel" style={style} size="small">
//             Экспорт
//           </Button>
//         }
//         filename={filename}
//       >
//         <ExcelSheet data={data} name={filename}>
//           {showPeriod && <ExcelColumn label="Период" />}
//           {fields.map((f) => (
//             <ExcelColumn
//               label={f.title}
//               value={f.dataIndex}
//               key={f.dataIndex}
//             />
//           ))}
//         </ExcelSheet>
//       </ExcelFile>
//     );
//   }
// }

const ExportExcel = (props) => {
  const { filename, data, fields, style, showPeriod } = props;

  return (
    <ExcelFile
      element={
        <Button icon="file-excel" style={style} size="small">
          Экспорт
        </Button>
      }
      filename={filename}
    >
      {showPeriod ? (
        <ExcelSheet data={data} name={filename}>
          <ExcelColumn label={`Период ${showPeriod[0]} - ${showPeriod[1]}`} />
          {fields.map((f) => (
            <ExcelColumn label={f.title} value={f.dataIndex} key={f.dataIndex} />
          ))}
        </ExcelSheet>
      ) : (
        <ExcelSheet data={data} name={filename}>
          {fields.map((f) => (
            <ExcelColumn label={f.title} value={f.dataIndex} key={f.dataIndex} />
          ))}
        </ExcelSheet>
      )}
    </ExcelFile>
  );
};

export default ExportExcel;
