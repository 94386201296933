import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import AnalyticalIndicators from '../pages/AnalyticalIndicators';
import Indicators from '../pages/ArmList';
import CommodityExchange from '../pages/CommodityExchange';
import Ecology from '../pages/Ecology';
import Economic from '../pages/Economic';
import Education from '../pages/Education';
import PopulationForecast from '../pages/Forecast/Population';
import VRPForecast from '../pages/Forecast/VRP';
import HealthCare from '../pages/HealthCare';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import OperationalSummary from '../pages/OperationalSummary';
import RoadConstruct from '../pages/RoadConstruct';
import Safety from '../pages/Safety';
import Social from '../pages/Social';
import Transport from '../pages/Transport';
import VideoStream from '../pages/VideoStream';

const URL_COMPONENTS = {
  '/safety': Safety,
  '/ecology': Ecology,
  '/economic': Economic,
  '/transport': Transport,
  '/videostream': VideoStream,
  '/education': Education,
  '/analytical-indicators': AnalyticalIndicators,
  '/forecast/population': PopulationForecast,
  '/forecast/vrp': VRPForecast,
  '/social': Social,
  '/commodity-exchange': CommodityExchange,
  '/healthcare': HealthCare,
  '/operational-summary': OperationalSummary,
  '/road-construction': RoadConstruct,
};

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: [],
    };
  }

  componentDidMount() {
    let user = window.localStorage.getItem('user_params');
    if (user) this.getNav(user);
  }

  getNav = (payload) => {
    const user = JSON.parse(payload);
    const { tabs, subtabs } = user.roles[0];
    const navigation = tabs
      .map((tab) => {
        return {
          ...tab,
          children: subtabs.filter((subtab) => subtab.tabId === tab.id),
        };
      })
      .filter((e) => e.children.length);

    this.setState({ navigation });
  };

  render() {
    const { navigation } = this.state;
    return (
      <Switch>
        <Route exact path="/signin" component={Login} />
        <Route exact path="/" render={(props) => <MainLayout {...props} component={Home} />} />
        {navigation.map((tab) => {
          const url = tab.url.split('cc.krgsc.kz')[1];
          return (
            <Route
              path={url}
              exact
              key={tab.id}
              render={(props) => (
                <MainLayout {...props} component={URL_COMPONENTS[url]} subtabs={tab.children} />
              )}
            />
          );
        })}
        <Route
          path="/indicators"
          exact
          render={(props) => <MainLayout {...props} component={Indicators} />}
        />
        <Route render={(props) => <MainLayout {...props} component={NotFound} />} />
      </Switch>
    );
  }
}

export default withRouter(Router);
