import { combineReducers } from 'redux';
import { createAction, handleActions } from 'redux-actions';

import { LOGIN, LOGOUT } from './auth';

export const REDIRECT = 'sckrg/redirect/REDIRECT';
export const redirect = createAction(REDIRECT);

const redirectTo = handleActions(
  {
    [REDIRECT]: { next: (state) => null },
    [LOGIN]: {
      next: (state, { payload }) => '/',
    },
    [LOGOUT]: { next: (state, { payload }) => '/signin' },
  },
  null
);

export default combineReducers({
  redirectTo,
});
