import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { WEEK_DAYS } from '../../constants';
import request from '../../utils/request';

const EcologyReportContext = createContext({});

function useEcologyReport() {
  return useContext(EcologyReportContext);
}

function EcologyReportProvider({ children }) {
  // -- STATE --
  const [loading, setLoading] = useState(false);
  //
  const [weekDaysList, setWeekDays] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [measuresList, setMeasuresList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [period, setPeriod] = useState({
    startDate: moment().startOf('week'),
    endDate: moment(),
  });
  const [weekDay, setWeekDay] = useState('');
  const [regionId, setRegionId] = useState([
    'г. Сарань',
    'г. Жезказган',
    'г. Балхаш',
    'г. Караганда',
    'г. Темиртау',
  ]);
  const [stationId, setStationId] = useState('');
  const [measureId, setMeasureId] = useState('');
  const [sourceId, setSourceId] = useState('');
  const [typeId, setTypeId] = useState('');
  const [mapData, setMapData] = useState([]);

  const [reportData, setReportData] = useState([]);

  const init = async () => {
    setLoading(true);
    try {
      setWeekDays(
        WEEK_DAYS.map((d) => ({
          label: d,
          value: d,
        }))
      );

      Promise.all([
        request('/api/ecologyReport/locality'),
        request('/api/ecologyReport/stations'),
        request('/api/ecologyReport/measures'),
        request('/api/ecologyReport/source'),
        request('/api/ecologyReport/type'),
        request(
          `/api/ecologyReport/dataAllLocality?locality=${regionId}&from=${moment(
            period.startDate
          ).format('DD-MM-YYYY')}&to=${moment(period.endDate).format('DD-MM-YYYY')}${
            stationId && `&station=${stationId}`
          }${measureId && `&measureName=${measureId}`}${sourceId && `&dataType=${sourceId}`}${
            typeId && `&type=${typeId}`
          }`
        ),
      ]).then(
        ([
          regionListResponse,
          stationListResponse,
          measuresListResponse,
          sourceListResponse,
          typeListResponse,
          mapDataList,
        ]) => {
          setRegionList(formatOptions(regionListResponse));
          setStationList(formatOptions(stationListResponse));
          setMeasuresList(formatOptions(measuresListResponse));
          setSourceList(formatOptions(sourceListResponse));
          setTypeList(formatOptions(typeListResponse));
          setMapData(mapDataList);
          setLoading(false);
        }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const fetchMapData = async () => {
    const mapResponse = await request(
      `/api/ecologyReport/dataAllLocality?locality=${regionId}&from=${moment(
        period.startDate
      ).format('DD-MM-YYYY')}&to=${moment(period.endDate).format('DD-MM-YYYY')}${
        stationId && `&station=${stationId}`
      }${measureId && `&measureName=${measureId}`}${sourceId && `&dataType=${sourceId}`}${
        typeId && `&type=${typeId}`
      }`
    );
    setMapData(mapResponse);
  };

  const handleReportDataFetch = async () => {
    try {
      setLoading(true);
      fetchMapData();
      const reportResponse = await request(
        `/api/ecologyReport/data?locality=${regionId}&from=${moment(period.startDate).format(
          'DD-MM-YYYY'
        )}&to=${moment(period.endDate).format('DD-MM-YYYY')}${
          stationId && `&station=${stationId}`
        }${measureId && `&measureName=${measureId}`}${sourceId && `&dataType=${sourceId}`}${
          typeId && `&type=${typeId}`
        }`
      );
      setReportData(
        reportResponse.map((r) => ({
          ...r,
          maxvalue: r.maxvalue.toFixed(5),
          avgvalue: r.avgvalue.toFixed(5),
          ci: r.ci.toFixed(2),
          np: r.np.toFixed(2),
        }))
      );
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleFilterReset = () => {
    setPeriod({
      startDate: moment().startOf('week'),
      endDate: moment(),
    });
    setWeekDay('');
    setRegionId('');
    setStationId('');
    setMeasureId('');
    setSourceId('');
    setTypeId('');
  };
  // Период
  const handlePeriod = ([startDate, endDate]) => setPeriod({ startDate, endDate });

  const handleWeekDay = ({ weekDay: id }) => setWeekDay(id);

  const handleRegion = ({ region: id }) => setRegionId(id);

  const handleStation = ({ station: id }) => setStationId(id);

  const handleMeasure = ({ measure: id }) => setMeasureId(id);

  const handleSource = ({ source: id }) => setSourceId(id);

  const handleType = ({ type: id }) => setTypeId(id);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    // -- FILTERS --
    // filter select options
    weekDaysList,
    regionList,
    stationList,
    measuresList,
    sourceList,
    typeList,
    mapData,
    //
    loading,
    //
    period,
    weekDay,
    regionId,
    stationId,
    measureId,
    sourceId,
    typeId,
    // handlers
    handlePeriod,
    handleWeekDay,
    handleRegion,
    handleStation,
    handleMeasure,
    handleSource,
    handleType,

    reportData,
    handleReportDataFetch,
    handleFilterReset,
  };

  return <EcologyReportContext.Provider value={value}>{children}</EcologyReportContext.Provider>;
}

export { EcologyReportProvider, useEcologyReport };

const formatOptions = (arr = []) =>
  arr.map((i) => ({
    label: i,
    value: i,
  }));
