import React from 'react';
import { Button } from 'antd';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import styles from './EcologyReport.module.scss';

export default function ExcelExport({ fileName = 'excel' }) {
  const exportToExcel = () => {
    const tbl = document.getElementsByTagName('table')[0];
    const wb = XLSX.utils.table_to_book(tbl, { sheet: 'Отчет' });
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const file = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(file, `${fileName}.xls`);
  };

  return (
    <Button icon={'download'} onClick={exportToExcel} className={styles.export_button}>
      Экспорт
    </Button>
  );
}
