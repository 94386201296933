import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import Hls from 'hls.js';

import LoadingSpinner from '../../components/LoadingSpinner';
import request from '../../utils/request';

export default function VideoStreamContainer({ auto = false }) {
  const [isLoading, setIsLoading] = useState(true);
  const [cams, setCams] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const videoRef = useRef(null);

  const getCameraList = () => {
    request('/api/videostream/camera', {
      method: 'GET',
    })
      .then((response) => {
        const sortedCams = response.sort((cam1, cam2) =>
          auto ? cam2.name.localeCompare(cam1.name) : cam1.name.localeCompare(cam2.name)
        );
        setCams(sortedCams);
      })
      .finally(() => setIsLoading(false));
  };

  const initializeVideoPlayer = (url) => {
    if (url && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
    }
  };

  const getVideoStreamUrl = () => {
    if (!selectedCamera) return;
    setIsLoading(true);
    request(`/api/videostream/camera/${selectedCamera.id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response?.player) {
          const url = response.player.url.replace('http://', 'https://');
          initializeVideoPlayer(url);
        }
      })
      .finally(() => setIsLoading(false));
  };

  function onChangeCamera(value) {
    setSelectedCamera(cams.filter((c) => c.id === value)[0]);
  }

  useEffect(() => {
    if (selectedCamera) {
      getVideoStreamUrl();
    }
  }, [selectedCamera]);

  useEffect(() => {
    getCameraList();
  }, [auto]);

  return (
    <div>
      <LoadingSpinner
        style={{ with: '100%', justifyContent: 'center' }}
        loading={isLoading}
        size={46}
      >
        <div style={{ marginBottom: 30, marginTop: 20 }}>
          <Select
            placeholder="Выберите камеру"
            onChange={onChangeCamera}
            value={selectedCamera?.id}
            style={{ width: 250 }}
            allowClear
          >
            {cams.map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {e.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <video id="videoElement" ref={videoRef} />
        </div>
      </LoadingSpinner>
    </div>
  );
}
