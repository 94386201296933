import React from 'react';

import { ChartCard } from '../../components/newDesign/ChartCard/ChartCard';
import Table from '../../components/Table';

const TableView = ({ indicator }) => {
  const uniqYear = Array.from(
    new Set([...indicator.fact.data, ...indicator.plan.data].map((e) => e.label))
  );
  const data = uniqYear.map((year) => {
    const plan = indicator.plan.data.find((e) => e.label === year);
    const fact = indicator.fact.data.find((e) => e.label === year);
    return {
      year: year + '',
      plan: plan ? plan.value : null,
      fact: fact ? fact.value : null,
    };
  });

  return (
    <ChartCard style={{ flex: 'unset', width: '45%' }} title={indicator.title}>
      <Table
        headers={[
          { label: 'Год', key: 'year' },
          { label: 'План', key: 'plan' },
          { label: 'Факт', key: 'fact' },
        ]}
        objects={data}
      />
    </ChartCard>
  );
};

export default TableView;
