import React from 'react';

const DamuMed = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/damu-med"
      title="damu-med"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};
export default DamuMed;
