import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Radio, Row, Tabs } from 'antd';

import { menGroups, womenGroups } from './constants';
import ForecastLineChart from './ForecastLineChart';

import { CaseMainTitle } from '../../../components/newDesign/CaseMainTitle/CaseMainTitle';
import RangeSlider from '../../../components/RangeSlider';
import { range } from '../../../utils/helpers';

const groupData = ['0-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-80', '81-90'];

let initialData = [
  // { label: 2013, men: 643844, women: 718933 },
  // { label: 2014, men: 647438, women: 722220 },
  { label: 2015, men: 651631, women: 726490 },
  { label: 2016, men: 655552, women: 729258 },
  { label: 2017, men: 655143, women: 727591 },
  { label: 2018, men: 654524, women: 726014 },
];
initialData = initialData.map((data) => ({
  ...data,
  all: data.men + data.women,
}));

const dispersionUpInitial = range(2009, 2018).map((value) => null);
const dispersionDownInitial = range(2009, 2018).map((value) => null);

const labels = range(2009, 2030);
const initialAll = initialData.map((row) => row.all);
const initialMenData = initialData.map((row) => row.men);
const initialWomenData = initialData.map((row) => row.women);

const defaultParameters = {
  survivalIndexMen: 10, //10,
  survivalIndexMenChange: -2.6,

  survivalIndexWomen: 9.4, //10,
  survivalIndexWomenChange: -3.8,

  saldoMigration: -13990, //-50000,
  saldoMigrationChange: -10,

  birthRateMen: 18667, //0,
  birthRateMenChange: -1.8,

  birthRateWomen: 16669, //0,
  birthRateWomenChange: -1.6,

  deathRateMen: 7, //5,
  deathRateWomen: 5, //5

  deviation: 0.1,
  deviationChange: 0.2,
};

const forecastTabs = [
  {
    key: '1',
    label: 'График',
  },
  {
    key: '2',
    label: 'Таблица',
  },
];

class PopulationForecast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'graphic',
      labels,
      all: initialAll,
      menData: initialMenData,
      menGroupData: [],
      womenData: initialWomenData,
      womenGroupData: [],
      dispersionUp: [...dispersionUpInitial],
      dispersionDown: [...dispersionDownInitial],
      activeTab: '1',

      ...defaultParameters,
    };
  }

  UNSAFE_componentWillMount() {
    this.calculatePopulation();
  }

  changeTab = (e) => {
    e.preventDefault();
    const { tab } = this.state;
    this.setState({ tab: tab === 'graphic' ? 'table' : 'graphic' });
  };

  handleChange = (key, value) => {
    const newValue = Number.isInteger(value) ? value : Number(value.toFixed(1));
    this.setState({ [key]: newValue });
  };

  handleIncrement = (key, increment = 1) => {
    const value = Number(this.state[key]) + increment;
    this.setState({
      [key]: Number.isInteger(value) ? value : value.toFixed(1),
    });
  };

  handleDecrement = (key, increment = 1) => {
    const value = Number(this.state[key]) - increment;
    this.setState({
      [key]: Number.isInteger(value) ? value : value.toFixed(1),
    });
  };

  calculatePopulation() {
    let newData = [...initialData];
    const years = range(2018, 2029);

    years.forEach((year) => {
      const populationMen = newData.find((el) => el.label === year).men;
      const populationWomen = newData.find((el) => el.label === year).women;
      const survivalIndexMen =
        year === 2018
          ? this.state.survivalIndexMen
          : newData.find((el) => el.label === year).survivalIndexMen;
      const survivalIndexWomen =
        year === 2018
          ? this.state.survivalIndexWomen
          : newData.find((el) => el.label === year).survivalIndexWomen;
      const saldoMigration =
        year === 2018
          ? this.state.saldoMigration
          : newData.find((el) => el.label === year).saldoMigration;
      const birthRateMen =
        year === 2018
          ? this.state.birthRateMen
          : newData.find((el) => el.label === year).birthRateMen;
      const birthRateWomen =
        year === 2018
          ? this.state.birthRateWomen
          : newData.find((el) => el.label === year).birthRateWomen;

      const { nextPopulationMen, menGroupData } = this.getNextYearPopulationMen(
        populationMen,
        survivalIndexMen,
        saldoMigration,
        birthRateMen
      );
      const { nextPopulationWomen, womenGroupData } = this.getNextYearPopulationWomen(
        populationWomen,
        survivalIndexWomen,
        saldoMigration,
        birthRateWomen
      );
      const nextSurvivalIndexMen =
        survivalIndexMen === 0
          ? survivalIndexMen
          : survivalIndexMen * (1 + this.state.survivalIndexMenChange / 100);
      const nextSurvivalIndexWomen =
        survivalIndexWomen === 0
          ? survivalIndexWomen
          : survivalIndexWomen * (1 + this.state.survivalIndexWomenChange / 100);
      const nextSaldoMigration =
        saldoMigration === 0
          ? saldoMigration
          : saldoMigration * (1 + this.state.saldoMigrationChange / 100);
      const nextBirthRateMen =
        birthRateMen === 0
          ? birthRateMen
          : birthRateMen * (1 + this.state.birthRateMenChange / 100);
      const nextBirthRateWomen =
        birthRateWomen === 0
          ? birthRateWomen
          : birthRateWomen * (1 + this.state.birthRateWomenChange / 100);

      newData = [
        ...newData,
        {
          label: year + 1,
          men: nextPopulationMen,
          menGroupData,
          women: nextPopulationWomen,
          womenGroupData,
          all: nextPopulationMen + nextPopulationWomen,
          survivalIndexMen: nextSurvivalIndexMen,
          survivalIndexWomen: nextSurvivalIndexWomen,
          saldoMigration: nextSaldoMigration,
          birthRateMen: nextBirthRateMen,
          birthRateWomen: nextBirthRateWomen,
        },
      ];
    });

    this.setState(
      {
        all: newData.map((row) => row.all),
        menData: newData.map((row) => row.men),
        menGroupData: newData.map((row) => row.menGroupData),
        womenData: newData.map((row) => row.women),
        womenGroupData: newData.map((row) => row.womenGroupData),
      },
      () => {
        const { all, deviation, deviationChange } = this.state;
        let dispersionUp = [...dispersionUpInitial];
        let dispersionDown = [...dispersionDownInitial];
        let base = 1;

        all.forEach((value, i) => {
          if (i >= 9) {
            const up = value * (1 + Math.pow(base, deviationChange) * deviation);
            const down = value * (1 - Math.pow(base, deviationChange) * deviation);
            dispersionUp = [...dispersionUp, up];
            dispersionDown = [...dispersionDown, down];
            base++;
          }
        });

        this.setState({
          dispersionUp,
          dispersionDown,
        });
      }
    );
  }

  getNextYearPopulationMen(totalPopulation, survivalIndexMen, saldoMigration, birthRateMen) {
    const { deathRateMen } = this.state;
    //
    let population = 0;
    let menGroupData = {};

    // const lastIndex = menGroups.length - 1;
    menGroups.forEach((group, index) => {
      let newPopulation = 0;
      const fraction = totalPopulation * group.percentage;
      if (index === 0) {
        newPopulation += birthRateMen * ((1000 - deathRateMen) / 1000);
        newPopulation += fraction * 0.9;
        const coef = survivalIndexMen * menGroups[index].multiplier;
        newPopulation -= (coef * fraction) / 1000;
        //
      }
      // else if (index === lastIndex) {
      //     newPopulation += fraction * 0.9;
      //     //
      //     const previousGroupFraction = totalPopulation * menGroups[index - 1].percentage;
      //     const coef = survivalIndexMen * menGroups[index - 1].multiplier;
      //     newPopulation += previousGroupFraction * 0.1;
      //     newPopulation -= coef * fraction / 1000;
      //     //
      // }
      else {
        newPopulation += fraction * 0.9;
        // newPopulation += fraction * 0.9 * ( (100 - (survivalIndexMen * group.multiplier))/100 );
        //
        const previousGroupFraction = totalPopulation * menGroups[index - 1].percentage;
        const coef = survivalIndexMen * menGroups[index].multiplier;
        newPopulation += previousGroupFraction * 0.1; // previousGroupFraction * 0.1 * ((100 - coef)/100);
        newPopulation -= (coef * fraction) / 1000;
        //
      }
      population += newPopulation;
      menGroupData[groupData[index]] = parseInt(newPopulation, 10);
    });

    //
    population += saldoMigration / 2;
    //
    return {
      nextPopulationMen: parseInt(population, 10),
      menGroupData,
    };
  }

  getNextYearPopulationWomen(totalPopulation, survivalIndexWomen, saldoMigration, birthRateWomen) {
    const { deathRateWomen } = this.state;
    //
    let population = 0;
    let womenGroupData = {};

    // const lastIndex = womenGroups.length - 1;
    womenGroups.forEach((group, index) => {
      let newPopulation = 0;
      const fraction = totalPopulation * group.percentage;
      if (index === 0) {
        newPopulation += birthRateWomen * ((1000 - deathRateWomen) / 1000);
        newPopulation += fraction * 0.9;
        const coef = survivalIndexWomen * womenGroups[index].multiplier;
        newPopulation -= (coef * fraction) / 1000;
        //
      }
      // else if (index === lastIndex) {
      //     newPopulation += fraction * 0.9;
      //     //
      //     const previousGroupFraction = totalPopulation * womenGroups[index - 1].percentage;
      //     const coef = survivalIndexWomen * womenGroups[index - 1].multiplier;
      //     newPopulation += previousGroupFraction * 0.1;
      //     newPopulation -= coef * fraction / 1000;
      //     //
      // }
      else {
        newPopulation += fraction * 0.9;
        // newPopulation += fraction * 0.9 * ( (100 - (survivalIndexMen * group.multiplier))/100 );
        const previousGroupFraction = totalPopulation * womenGroups[index - 1].percentage;
        const coef = survivalIndexWomen * womenGroups[index].multiplier;
        newPopulation += previousGroupFraction * 0.1; // previousGroupFraction * 0.1 * ((100 - coef)/100);
        newPopulation -= (coef * fraction) / 1000;
      }
      population += newPopulation;
      womenGroupData[groupData[index]] = parseInt(newPopulation, 10);
    });

    population += saldoMigration / 2;
    return {
      nextPopulationWomen: parseInt(population, 10),
      womenGroupData,
    };
  }

  handleParametersReset = () => {
    this.setState(defaultParameters, this.calculatePopulation);
  };

  render() {
    const {
      tab,
      labels,
      all,
      menData,
      menGroupData,
      womenData,
      womenGroupData,
      dispersionUp,
      dispersionDown,
    } = this.state;
    return (
      <div style={{ padding: 12 }}>
        <CaseMainTitle>Прогнозирование</CaseMainTitle>
        <Row className={'forecast-tab-header'}>
          <Col>
            <NavLink
              to="/forecast/population"
              className={'forecast-tab-header-title'}
              activeClassName="forecast-tab-header-title-active"
            >
              Прогноз численности населения
            </NavLink>
            <NavLink
              to="/forecast/vrp"
              className={'forecast-tab-header-title'}
              activeClassName="forecast-tab-header-title-active"
            >
              Прогноз ВРП
            </NavLink>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={18}>
            <h2 className="page-title mt20">Прогнозирование численности населения</h2>
            <Tabs
              defaultActiveKey="graphic"
              tabBarStyle={{ borderBottom: '1px solid #40404D', color: '#fff' }}
            >
              <Tabs.TabPane tab="График" key="graphic">
                <div className={'forecast-chart-wrapper'}>
                  <h4 className={'forecast-chart-title'}>График</h4>
                  <ForecastLineChart
                    labels={labels}
                    all={all}
                    menData={menData}
                    womenData={womenData}
                    dispersionUp={dispersionUp}
                    dispersionDown={dispersionDown}
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Таблица" key="table">
                <div style={{ overflow: 'scroll' }}>
                  <div className="pull-right" style={{ margin: '10px' }}>
                    Человек
                  </div>
                  <table className="tableStyled">
                    <thead>
                      <tr>
                        <th>Год</th>
                        <th>2015</th>
                        <th>2016</th>
                        <th>2017</th>
                        <th>2018</th>
                        <th>2019</th>
                        <th>2020</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                        <th>2024</th>
                        <th>2025</th>
                        <th>2026</th>
                        <th>2027</th>
                        <th>2028</th>
                        <th>2029</th>
                        <th>2030</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ВСЕ</td>
                        {all.map((count, index) => (
                          <td key={index}>{count}</td>
                        ))}
                      </tr>
                      <tr>
                        <td>Мужчины</td>
                        {menData.map((count, index) => (
                          <td key={index}>{count}</td>
                        ))}
                      </tr>
                      {groupData.map((g, index) => {
                        const yearData = [];
                        menGroupData.forEach((gd) => {
                          if (gd && gd[g]) yearData.push(gd[g]);
                          else yearData.push('-');
                        });
                        return (
                          <tr key={index}>
                            <td>{g}</td>
                            {yearData.map((count, index) => (
                              <td key={index}>{count}</td>
                            ))}
                          </tr>
                        );
                      })}
                      <tr>
                        <td>Женщины</td>
                        {womenData.map((count, index) => (
                          <td key={index}>{count}</td>
                        ))}
                      </tr>
                      {groupData.map((g, index) => {
                        const yearData = [];
                        womenGroupData.forEach((gd) => {
                          if (gd && gd[g]) yearData.push(gd[g]);
                          else yearData.push('-');
                        });
                        return (
                          <tr key={index}>
                            <td>{g}</td>
                            {yearData.map((count, index) => (
                              <td key={index}>{count}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col span={6}>
            <div className={'forecast-parameters'}>
              <h4 className="fc-controls__heading">Параметры</h4>
              <div className="fc-controls">
                <div className="fc-controls__list scrollbar">
                  <RangeSlider
                    title="Смертность мужчин на 1000 человек"
                    tooltip="Коэффициент смертности мужчин, число умерших на 1000 человек"
                    value={this.state.survivalIndexMen}
                    min={0}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('survivalIndexMen', value)}
                    handleDecrement={() => this.handleDecrement('survivalIndexMen', 0.1)}
                    handleIncrement={() => this.handleIncrement('survivalIndexMen', 0.1)}
                  />
                  <RangeSlider
                    title="Изменение смертности мужчин"
                    tooltip="Изменения коэффициента смертности мужчин в последующих годах"
                    value={this.state.survivalIndexMenChange}
                    measure="%"
                    min={-10}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('survivalIndexMenChange', value)}
                    handleDecrement={() => this.handleDecrement('survivalIndexMenChange', 0.1)}
                    handleIncrement={() => this.handleIncrement('survivalIndexMenChange', 0.1)}
                  />

                  <RangeSlider
                    title="Смертность женщин на 1000 человек"
                    tooltip="Коэффициент смертности женщин, число умерших на 1000 человек"
                    value={this.state.survivalIndexWomen}
                    min={0}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('survivalIndexWomen', value)}
                    handleDecrement={() => this.handleDecrement('survivalIndexWomen', 0.1)}
                    handleIncrement={() => this.handleIncrement('survivalIndexWomen', 0.1)}
                  />
                  <RangeSlider
                    title="Изменение смертности женщин"
                    tooltip="Изменения коэффициента смертности мужчин в последующих годах"
                    value={this.state.survivalIndexWomenChange}
                    measure="%"
                    min={-10}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('survivalIndexWomenChange', value)}
                    handleDecrement={() => this.handleDecrement('survivalIndexWomenChange', 0.1)}
                    handleIncrement={() => this.handleIncrement('survivalIndexWomenChange', 0.1)}
                  />

                  <RangeSlider
                    title="Сальдо миграции"
                    tooltip="Разница между количеством лиц, прибывших и количеством лиц, выбывших.
                  Пропорциональное влияние на численность населения в зависимости от значения."
                    value={this.state.saldoMigration}
                    min={-50000}
                    max={100000}
                    handleChange={(value) => this.handleChange('saldoMigration', value)}
                    handleDecrement={() => this.handleDecrement('saldoMigration', 10)}
                    handleIncrement={() => this.handleIncrement('saldoMigration', 10)}
                  />
                  <RangeSlider
                    title="Изменение сальдо миграции"
                    tooltip="Изменение сальдо миграции последующих годах."
                    value={this.state.saldoMigrationChange}
                    measure="%"
                    min={-50}
                    max={50}
                    handleChange={(value) => this.handleChange('saldoMigrationChange', value)}
                    handleDecrement={() => this.handleDecrement('saldoMigrationChange')}
                    handleIncrement={() => this.handleIncrement('saldoMigrationChange')}
                  />

                  <RangeSlider
                    title="Рождаемость (мальчики)"
                    tooltip="Коэффициент рождаемости мальчиков, число родившихся мальчиков на 1000 женщин"
                    value={this.state.birthRateMen}
                    min={0}
                    max={20000}
                    handleChange={(value) => this.handleChange('birthRateMen', value)}
                    handleDecrement={() => this.handleDecrement('birthRateMen')}
                    handleIncrement={() => this.handleIncrement('birthRateMen')}
                  />
                  <RangeSlider
                    title="Изменение рождаемости (мальчики)"
                    tooltip="Изменения  рождаемости мальчиков в последующих годах"
                    value={this.state.birthRateMenChange}
                    measure="%"
                    min={-10}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('birthRateMenChange', value)}
                    handleDecrement={() => this.handleDecrement('birthRateMenChange', 0.1)}
                    handleIncrement={() => this.handleIncrement('birthRateMenChange', 0.1)}
                  />

                  <RangeSlider
                    title="Рождаемость (девочки)"
                    tooltip="Коэффициент рождаемости девочек, число родившихся девочек на 1000 женщин"
                    value={this.state.birthRateWomen}
                    min={0}
                    max={20000}
                    handleChange={(value) => this.handleChange('birthRateWomen', value)}
                    handleDecrement={() => this.handleDecrement('birthRateWomen')}
                    handleIncrement={() => this.handleIncrement('birthRateWomen')}
                  />
                  <RangeSlider
                    title="Изменение рождаемости (девочки)"
                    tooltip="Изменения  рождаемости девочек в последующих годах"
                    value={this.state.birthRateWomenChange}
                    measure="%"
                    min={-10}
                    max={10}
                    step={0.1}
                    handleChange={(value) => this.handleChange('birthRateWomenChange', value)}
                    handleDecrement={() => this.handleDecrement('birthRateWomenChange', 0.1)}
                    handleIncrement={() => this.handleIncrement('birthRateWomenChange', 0.1)}
                  />

                  <RangeSlider
                    title="Смертность новорожденных (мальчики)"
                    value={this.state.deathRateMen}
                    min={5}
                    max={20}
                    handleChange={(value) => this.handleChange('deathRateMen', value)}
                    handleDecrement={() => this.handleDecrement('deathRateMen')}
                    handleIncrement={() => this.handleIncrement('deathRateMen')}
                  />
                  <RangeSlider
                    title="Смертность новорожденных (девочки)"
                    value={this.state.deathRateWomen}
                    min={5}
                    max={20}
                    handleChange={(value) => this.handleChange('deathRateWomen', value)}
                    handleDecrement={() => this.handleDecrement('deathRateWomen')}
                    handleIncrement={() => this.handleIncrement('deathRateWomen')}
                  />

                  <RangeSlider
                    title="Отклонение"
                    tooltip="Используется для вычислении значения погрешности"
                    value={this.state.deviation}
                    min={0}
                    max={1.0}
                    step={0.1}
                    handleChange={(value) => this.handleChange('deviation', value)}
                    handleDecrement={() => this.handleDecrement('deviation', 0.1)}
                    handleIncrement={() => this.handleIncrement('deviation', 0.1)}
                  />
                  <RangeSlider
                    title="Изменение отклонения"
                    tooltip="Используется для вычислении значения погрешности"
                    value={this.state.deviationChange}
                    min={0}
                    max={1.0}
                    step={0.1}
                    handleChange={(value) => this.handleChange('deviationChange', value)}
                    handleDecrement={() => this.handleDecrement('deviationChange', 0.1)}
                    handleIncrement={() => this.handleIncrement('deviationChange', 0.1)}
                  />
                </div>
              </div>
              <Button
                onClick={this.calculatePopulation.bind(this)}
                type="primary"
                block
                style={{ marginTop: '20px' }}
              >
                Спрогнозировать
              </Button>
              <Button
                onClick={this.handleParametersReset}
                block
                style={{ marginTop: '10px', marginBottom: '20px' }}
              >
                Сбросить параметры
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PopulationForecast;
