import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Radio, Row } from 'antd';

import VrpLineChart from './VrpLineChart';

import { CaseMainTitle } from '../../../components/newDesign/CaseMainTitle/CaseMainTitle';
import RangeSlider from '../../../components/RangeSlider';
import { numberWithCommas } from '../../../utils/helpers';

const dispersionInitial = [3482908.9];
const dispersionUpInitial = [null];

const dispersionDownInitial = [
  null, //10171464 * (1 - Math.pow(1, 0.5) * 0.1),
  //null //11558315.3 * (1 - Math.pow(1, 0.5) * 0.1),
];

class VRP extends Component {
  constructor() {
    super();

    this.state = {
      tab: 'graphic',
      inputs: [
        {
          branchId: 3,
          ifo: 102.9, //103.4,
          deflator: 105.1, //102.6
        },
        {
          branchId: 5,
          ifo: 103.8, //103.4,
          deflator: 105.1, //102.6
        },
        {
          branchId: 6,
          ifo: 103.3, //104,
          deflator: 105.1, //110.5
        },
        {
          branchId: 7,
          ifo: 103.3, //102.4,
          deflator: 105.1, //105.7
        },
        {
          branchId: 8,
          ifo: 90.5, //99,
          deflator: 105.1, //109.7
        },
        {
          branchId: 9,
          ifo: 100.8, //104.1,
          deflator: 105.1, //105.3
        },
        {
          branchId: 11,
          ifo: 102.0, //107.6,
          deflator: 105.1, //103
        },
        {
          branchId: 12,
          ifo: 105.0, //104.6,
          deflator: 105.1, //104.9
        },
        {
          branchId: 13,
          ifo: 104.2, //102.6,
          deflator: 105.1, //100.9
        },
        {
          branchId: 14,
          ifo: 101.0, //102.7,
          deflator: 105.1, //100.5
        },
        {
          branchId: 15,
          ifo: 100.0, //102,
          deflator: 105.1, //102
        },
        {
          branchId: 16,
          ifo: 102.5, //104.2,
          deflator: 105.1, //128.9
        },
      ],
      // ifo: 104,
      // deflator: 104.5,
      deviation: 0.05,
      deviationChange: 0.5,
      dispersion: [...dispersionInitial],
      dispersionUp: [...dispersionUpInitial],
      dispersionDown: [...dispersionDownInitial],
      branches: [
        {
          id: 1,
          title: 'Валовой региональный продукт',
          2018: 3482908.9,
          byFormula: false,
          calculatedBy: [16, 3, 6, 7, 8, 9, 11, 12, 13, 14, 15],
        },
        {
          id: 2,
          title: 'Производство товаров',
          2018: 1726579.9,
          byFormula: false,
          calculatedBy: [3, 6, 7, 8, 9],
        },
        {
          id: 3,
          title: 'Сельское, лесное и рыбное хозяйство',
          2018: 289529.9,
          byFormula: true,
        },
        {
          id: 4,
          title: 'Промышленность',
          2018: 1248840.1,
          byFormula: false,
          calculatedBy: [6, 7, 8],
        },
        {
          id: 5,
          title: 'Горнодобывающая промышленность и разработка карьеров',
          2018: 451946.5,
          byFormula: true,
        },
        {
          id: 6,
          title: 'Обрабатывающая промышленность',
          2018: 710911.8,
          byFormula: true,
        },
        {
          id: 7,
          title: 'Электроснабжение, подача газа, пара и воздушное кондиционирование',
          2018: 78018.6,
          byFormula: true,
        },
        {
          id: 8,
          title:
            'Водоснабжение; канализационная система, контроль над сбором и распределением отходов',
          2018: 7963.1,
          byFormula: true,
        },
        {
          id: 9,
          title: 'Строительство',
          2018: 188209.9,
          byFormula: true,
        },
        {
          id: 10,
          title: 'Производство услуг',
          2018: 1563085.8,
          byFormula: false,
          calculatedBy: [11, 12, 13, 14, 15],
        },
        {
          id: 11,
          title: 'Оптовая и розничная торговля; ремонт автомобилей и мотоциклов',
          2018: 378497.6,
          byFormula: true,
        },
        {
          id: 12,
          title: 'Транспорт и складирование',
          2018: 349152.6,
          byFormula: true,
        },
        {
          id: 13,
          title: 'Информация и связь',
          2018: 21600.5,
          byFormula: true,
        },
        {
          id: 14,
          title: 'Операции с недвижимым имуществом',
          2018: 235360.2,
          byFormula: true,
        },
        {
          id: 15,
          title: 'Прочие услуги',
          2018: 578474.8,
          byFormula: true,
        },
        {
          id: 16,
          title: 'Чистые налоги',
          2018: 193243.1,
          byFormula: true,
        },
        {
          id: 17,
          title: 'Валовая добавленная стоимость',
          2018: 3289665.7,
          byFormula: false,
          calculatedBy: [3, 6, 7, 8, 9, 11, 12, 13, 14, 15],
        },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.calculateVRP();
  }

  changeTab = (e) => {
    e.preventDefault();
    const { tab } = this.state;
    this.setState({ tab: tab === 'graphic' ? 'table' : 'graphic' });
  };

  handleChange = (key, value, index = null) => {
    if (index !== null) {
      this.setState({
        inputs: [
          ...this.state.inputs.slice(0, index),
          {
            ...this.state.inputs[index],
            [key]: Number.isInteger(value) ? value : value.toFixed(1),
          },
          ...this.state.inputs.slice(index + 1),
        ],
      });
    } else {
      this.setState({
        [key]: Number.isInteger(value) ? value : value.toFixed(1),
      });
    }
  };

  handleDecrement = (key, index = null) => {
    if (index !== null) {
      const value = Number(this.state.inputs[index][key]) - 1;
      this.setState({
        inputs: [
          ...this.state.inputs.slice(0, index),
          {
            ...this.state.inputs[index],
            [key]: Number.isInteger(value) ? value : value.toFixed(1),
          },
          ...this.state.inputs.slice(index + 1),
        ],
      });
    } else {
      const value = Number(this.state[key]) - 1;
      this.setState({
        [key]: Number.isInteger(value) ? value : value.toFixed(1),
      });
    }
  };

  handleIncrement = (key, index = null) => {
    if (index !== null) {
      const value = Number(this.state.inputs[index][key]) + 1;
      this.setState({
        inputs: [
          ...this.state.inputs.slice(0, index),
          {
            ...this.state.inputs[index],
            [key]: Number.isInteger(value) ? value : value.toFixed(1),
          },
          ...this.state.inputs.slice(index + 1),
        ],
      });
    } else {
      const value = Number(this.state[key]) + 1;
      this.setState({
        [key]: Number.isInteger(value) ? value : value.toFixed(1),
      });
    }
  };

  calculateVRP() {
    let branches = [...this.state.branches];
    this.state.branches.forEach((branch, index) => {
      if (branch.byFormula) {
        const input = this.state.inputs.find((input) => input.branchId === branch.id);

        const measure2019 = (branch[2018] * input.ifo * input.deflator) / 10000;
        const measure2020 = (measure2019 * input.ifo * input.deflator) / 10000;
        const measure2021 = (measure2020 * input.ifo * input.deflator) / 10000;
        const measure2022 = (measure2021 * input.ifo * input.deflator) / 10000;
        const measure2023 = (measure2022 * input.ifo * input.deflator) / 10000;

        branches = [
          ...branches.slice(0, index),
          {
            ...branch,
            2019: measure2019,
            2020: measure2020,
            2021: measure2021,
            2022: measure2022,
            2023: measure2023,
          },
          ...branches.slice(index + 1),
        ];
      }
    });

    this.setState({ branches }, () => {
      let branches = [...this.state.branches];
      this.state.branches.forEach((branch, index) => {
        if (!branch.byFormula) {
          /* const measure2018 = this.state.branches.filter(b => branch.calculatedBy.includes(b.id))
                                            .reduce((acc, current) => acc + current[2018], 0);*/
          const measure2019 = this.state.branches
            .filter((b) => branch.calculatedBy.includes(b.id))
            .reduce((acc, current) => acc + current[2019], 0);
          const measure2020 = this.state.branches
            .filter((b) => branch.calculatedBy.includes(b.id))
            .reduce((acc, current) => acc + current[2020], 0);
          const measure2021 = this.state.branches
            .filter((b) => branch.calculatedBy.includes(b.id))
            .reduce((acc, current) => acc + current[2021], 0);
          const measure2022 = this.state.branches
            .filter((b) => branch.calculatedBy.includes(b.id))
            .reduce((acc, current) => acc + current[2022], 0);
          const measure2023 = this.state.branches
            .filter((b) => branch.calculatedBy.includes(b.id))
            .reduce((acc, current) => acc + current[2023], 0);

          branches = [
            ...branches.slice(0, index),
            {
              ...branch,
              2019: measure2019,
              2020: measure2020,
              2021: measure2021,
              2022: measure2022,
              2023: measure2023,
            },
            ...branches.slice(index + 1),
          ];
        }
      });

      this.setState({ branches }, () => {
        const vrp = this.state.branches[0];
        const { deviation, deviationChange } = this.state;
        this.setState({
          dispersion: [...dispersionInitial, vrp[2019], vrp[2020], vrp[2021], vrp[2022], vrp[2023]],
          dispersionUp: [
            ...dispersionUpInitial,
            vrp[2019] * (1 + Math.pow(2, deviationChange) * deviation),
            vrp[2020] * (1 + Math.pow(3, deviationChange) * deviation),
            vrp[2021] * (1 + Math.pow(4, deviationChange) * deviation),
            vrp[2022] * (1 + Math.pow(5, deviationChange) * deviation),
            vrp[2023] * (1 + Math.pow(6, deviationChange) * deviation),
          ],
          dispersionDown: [
            ...dispersionDownInitial,
            vrp[2019] * (1 - Math.pow(2, deviationChange) * deviation),
            vrp[2020] * (1 - Math.pow(3, deviationChange) * deviation),
            vrp[2021] * (1 - Math.pow(4, deviationChange) * deviation),
            vrp[2022] * (1 - Math.pow(5, deviationChange) * deviation),
            vrp[2023] * (1 - Math.pow(6, deviationChange) * deviation),
          ],
        });
      });
    });
  }

  render() {
    const { tab, dispersion, dispersionDown, dispersionUp } = this.state;
    return (
      <div style={{ padding: 12 }}>
        <CaseMainTitle>Прогнозирование</CaseMainTitle>
        {/* this not important <Button type="primary" style={{ marginRight: 10 }}>
          <Link to="/forecast/population">Прогноз численности населения</Link>
        </Button>
        <Button type="primary">
          <Link to="/forecast/vrp">Прогноз ВРП</Link>
        </Button> */}
        <NavLink to="/forecast/population" className={'ant-btn'} activeClassName="ant-btn-primary">
          Прогноз численности населения
        </NavLink>
        <NavLink to="/forecast/vrp" className={'ant-btn'} activeClassName="ant-btn-primary">
          Прогноз ВРП
        </NavLink>

        <h1 className="page-title mt20">Прогнозирование ВРП (млрд. тенге)</h1>

        <Row gutter={10}>
          <Col span={18}>
            <Radio.Group
              defaultValue={tab}
              buttonStyle="solid"
              onChange={this.changeTab}
              style={{ margin: '5px' }}
            >
              <Radio.Button value="graphic">График</Radio.Button>
              <Radio.Button value="table">Таблица</Radio.Button>
            </Radio.Group>

            {tab === 'graphic' && (
              <VrpLineChart
                key={[...dispersion, ...dispersionDown, ...dispersionUp]}
                id="vrp_wrapper"
                dispersion={dispersion}
                dispersionUp={dispersionUp}
                dispersionDown={dispersionDown}
              />
            )}

            {tab === 'table' && (
              <div>
                <div className="pull-right" style={{ color: '#fff' }}>
                  {/* <h3>млрд. тенге</h3> */}
                </div>
                <table className="tableStyled">
                  <thead>
                    <tr>
                      <th>Наименование</th>
                      <th>2018</th>
                      <th>2019</th>
                      <th>2020</th>
                      <th>2021</th>
                      <th>2022</th>
                      <th>2023</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.branches.map((branch, index) => (
                      <tr
                        key={index}
                        style={index === 0 ? { background: '#204768', fontWeight: 'bold' } : null}
                      >
                        <td style={{ width: '50%' }}>{branch.title}</td>
                        <td>
                          {branch[2018] ? numberWithCommas(branch[2018].toFixed(2), ' ') : '-'}
                        </td>
                        <td>
                          {branch[2019] ? numberWithCommas(branch[2019].toFixed(2), ' ') : '-'}
                        </td>
                        <td>
                          {branch[2020] ? numberWithCommas(branch[2020].toFixed(2), ' ') : '-'}
                        </td>
                        <td>
                          {branch[2021] ? numberWithCommas(branch[2021].toFixed(2), ' ') : '-'}
                        </td>
                        <td>
                          {branch[2022] ? numberWithCommas(branch[2022].toFixed(2), ' ') : '-'}
                        </td>
                        <td>
                          {branch[2023] ? numberWithCommas(branch[2023].toFixed(2), ' ') : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
          <Col span={6}>
            <h4 className="fc-controls__heading">Параметры</h4>
            <div className="fc-controls">
              <div className="fc-controls__list scrollbar">
                {this.state.inputs.map((input, index) => {
                  const branch = this.state.branches.find((branch) => branch.id === input.branchId);
                  return (
                    <div key={index}>
                      <RangeSlider
                        title={`ИФО ВДС ${branch.title}`}
                        value={input.ifo}
                        measure="%"
                        min={80}
                        max={110}
                        step={0.1}
                        handleChange={(value) => this.handleChange('ifo', value, index)}
                        handleDecrement={() => this.handleDecrement('ifo', index)}
                        handleIncrement={() => this.handleIncrement('ifo', index)}
                      />
                      <RangeSlider
                        title={`Дефлятор ВДС ${branch.title}`}
                        value={input.deflator}
                        measure="%"
                        min={80}
                        max={110}
                        step={0.1}
                        handleChange={(value) => this.handleChange('deflator', value, index)}
                        handleDecrement={() => this.handleDecrement('deflator', index)}
                        handleIncrement={() => this.handleIncrement('deflator', index)}
                      />
                    </div>
                  );
                })}
                <RangeSlider
                  title="Отклонение"
                  value={this.state.deviation}
                  min={0}
                  max={1.0}
                  step={0.05}
                  handleChange={(value) => this.handleChange('deviation', value)}
                  handleDecrement={() => this.handleDecrement('deviation')}
                  handleIncrement={() => this.handleIncrement('deviation')}
                />
                <RangeSlider
                  title="Изменение отклонения"
                  value={this.state.deviationChange}
                  min={0}
                  max={1.0}
                  step={0.1}
                  handleChange={(value) => this.handleChange('deviationChange', value)}
                  handleDecrement={() => this.handleDecrement('deviationChange')}
                  handleIncrement={() => this.handleIncrement('deviationChange')}
                />
              </div>
            </div>
            <Button
              onClick={this.calculateVRP.bind(this)}
              type="primary"
              block
              style={{ padding: '5px', marginTop: '10px' }}
            >
              Спрогнозировать
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default VRP;
