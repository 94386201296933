import React from 'react';
import { Button } from 'antd';

import { useArm } from './context';

import { FilterItem } from '../../components/newDesign/FilterItem/FilterItem';
import { FilterWrapper } from '../../components/newDesign/FilterWrapper/FilterWrapper';
import { SelectList } from '../../components/newDesign/SelectList/SelectList';

function Filters() {
  const {
    // select options
    periodList,
    directionsList,
    // active
    periodId,
    directionId,
    // handlers
    handlePeriod,
    handleDirection,
    handleIndicatorsFetch,
  } = useArm();

  return (
    <FilterWrapper>
      <FilterItem>
        <SelectList
          label={'Период:'}
          defaultValue={periodId}
          onChange={(value) => handlePeriod(value)}
          showKey="label"
          valueKey="value"
          list={periodList}
        />
      </FilterItem>
      <FilterItem>
        <SelectList
          label={'Направление:'}
          defaultValue={directionId}
          onChange={(value) => handleDirection(value)}
          showKey="label"
          valueKey="value"
          list={directionsList}
        />
      </FilterItem>
      <FilterItem action>
        <Button onClick={handleIndicatorsFetch}>Обновить</Button>
      </FilterItem>
    </FilterWrapper>
  );
}

export default Filters;
