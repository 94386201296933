import React, { Component } from 'react';

import Ecology1 from './Ecology1';
import Ecology2 from './Ecology2';
import Weather from './Weather';
import WeatherRP5 from './WeatherRP5';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';
import EcologyReport from '../EcologyReport';

const NAME_COMP = {
  Газоанализаторы: <Ecology1 />,
  'Загрязнение воздуха': <Ecology2 />,
  'Экологическая отчетность': <EcologyReport />,
  Погода: <Weather />,
  'Исторические данные по погоде': <WeatherRP5 />,
};

export default class Ecology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: Object.keys(NAME_COMP)[0],
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { subtabs } = this.props;
    return (
      <MainWrapper>
        <CaseMainTitle>Экология</CaseMainTitle>
        <RadioGroup
          onChange={this.radioChange}
          value={this.state.radio}
          list={subtabs.map((i) => ({
            key: i.name,
            value: i.name,
          }))}
        />
        {Object.keys(NAME_COMP).map((nav) => {
          if (nav === this.state.radio) return NAME_COMP[nav];
          else return null;
        })}
      </MainWrapper>
    );
  }
}
