import React, { Component } from 'react';

import Industry from './CommodityExchange';
import IndustryAnalytics from './IndustryAnalytics';

import { CaseMainTitle } from '../../components/newDesign/CaseMainTitle';
import { MainWrapper } from '../../components/newDesign/MainWrapper/MainWrapper';
import { RadioGroup } from '../../components/newDesign/RadioGroup';

const NAME_COMP = {
  Промышленность: <Industry />,
  'Аналитика промышленности': <IndustryAnalytics />,
};
export default class CommodityExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: Object.keys(NAME_COMP)[0],
    };
  }

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { subtabs } = this.props;
    return (
      <MainWrapper>
        <CaseMainTitle>Промышленность</CaseMainTitle>

        <RadioGroup
          onChange={this.radioChange}
          value={this.state.radio}
          list={subtabs.map((i) => ({
            key: i.name,
            value: i.name,
          }))}
        />

        {Object.keys(NAME_COMP).map((nav) => {
          if (nav === this.state.radio) return NAME_COMP[nav];
          else return null;
        })}
      </MainWrapper>
    );
  }
}
