import React, { Component } from 'react';
import { Radio } from 'antd';

class PlumbingSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'waterMeters',
    };
  }

  changeTab = (e) => {
    this.setState({ tab: e.target.value });
  };

  render() {
    const { tab } = this.state;
    return (
      <div className="p-10 h-100">
        <h1 className="page-title">Водопроводная система</h1>{' '}
        <Radio.Group
          defaultValue={tab}
          buttonStyle="solid"
          onChange={this.changeTab}
          style={{ margin: '5px' }}
        >
          <Radio.Button value="waterMeters">Приборы учета воды</Radio.Button>
          <Radio.Button value="waterSupplyNetwork">Водопроводная сеть</Radio.Button>
          <Radio.Button value="drainageSystem">Система водоотведения</Radio.Button>
          <Radio.Button value="waterSupplyFailures">Аварии системы водоснабжения</Radio.Button>
        </Radio.Group>
        {tab === 'waterMeters' && (
          <iframe
            src="https://bicloud.krgsc.kz/public/dashboards/28"
            title="Water Meters"
            style={{ border: 'none' }}
            width="100%"
            height="100%"
          />
        )}
        {tab === 'waterSupplyNetwork' && (
          <iframe
            src="https://bicloud.krgsc.kz/public/dashboards/30"
            title="Water Supply Network"
            style={{ border: 'none' }}
            width="100%"
            height="100%"
          />
        )}
        {tab === 'drainageSystem' && (
          <iframe
            src="https://bicloud.krgsc.kz/public/dashboards/31"
            title="Drainage System"
            style={{ border: 'none' }}
            width="100%"
            height="100%"
          />
        )}
        {tab === 'waterSupplyFailures' && (
          <iframe
            src="https://bicloud.krgsc.kz/public/dashboards/32"
            title="Water Supply Failures"
            style={{ border: 'none' }}
            width="100%"
            height="100%"
          />
        )}
      </div>
    );
  }
}

export default PlumbingSystem;
