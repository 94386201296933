export const CAMERAS = [
  {
    city: 'Караганда',
    items: [
      { title: 'Дороги', value: 1561 },
      { title: 'Государственные учреждения', value: 259 },
      { title: 'Организации образования', value: 3633 },
      { title: 'Общественные места', value: 1126 },
      { title: 'Дворы', value: 360 },
    ],
  },
  {
    city: 'Темиртау',
    items: [
      { title: 'Дороги', value: 165 },
      { title: 'Государственные учреждения', value: 12 },
      { title: 'Организации образования', value: 82 },
      { title: 'Общественные места', value: 61 },
      { title: 'Дворы', value: 98 },
    ],
  },
  {
    city: 'Сарань',
    items: [
      { title: 'Дороги', value: 116 },
      { title: 'Организации образования', value: 1 },
      { title: 'Общественные места', value: 49 },
    ],
  },
  {
    city: 'Осакаровка',
    items: [
      { title: 'Дороги', value: 44 },
      { title: 'Государственные учреждения', value: 25 },
      { title: 'Организации образования', value: 6 },
      { title: 'Общественные места', value: 28 },
      { title: 'Дворы', value: 5 },
    ],
  },
];

export const STATS = [
  {
    color: '#12C9D7',
    key: 'appeals',
    title: 'Обращения граждан',
    count: 0,
    icon: '/icons/appeals_icon.svg',
    countYear: 0,
  },
  {
    color: '#F44129',
    key: 'ambulance',
    title: 'Скорая помощь',
    count: 0,
    icon: '/icons/medicine_icon.svg',
    countYear: 0,
  },
  {
    color: '#1EEB64',
    key: 'fireService',
    title: 'Пожарная служба',
    count: 0,
    icon: '/icons/fire_icon.svg',
    countYear: 0,
  },
  {
    color: '#4075FF',
    key: 'police',
    title: 'Полиция',
    count: 0,
    icon: '/icons/police_icon.svg',
    countYear: 0,
  },
  {
    color: '#CE42FF',
    key: 'emergency',
    title: 'Чрезвычайная ситуация',
    count: 0,
    icon: '/icons/warning_icon.svg',
    countYear: 0,
  },
];
