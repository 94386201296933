import React from 'react';

import { useArm } from './context';
import Filters from './Filters';

import LoadingSpinner from '../../components/LoadingSpinner';
import { ChartCard } from '../../components/newDesign/ChartCard/ChartCard';
import { RowWrapper } from '../../components/newDesign/RowWrapper/RowWrapper';
import { CHART_MAIN_COLOR } from '../../components/newDesign/utils';
import VerticalStackedBarChart from '../../components/visualizations/VerticalStackedBarChart';

export default function ArmListContainer() {
  const { indicatorsList, loading } = useArm();
  return (
    <div className="h-100" style={{ marginTop: 20 }}>
      <Filters />
      <LoadingSpinner loading={loading}>
        <RowWrapper>
          {indicatorsList.map((indicator, index) => (
            <ChartCard key={index} style={{ flex: 'unset', width: '45%' }} title={indicator.nameRu}>
              <VerticalStackedBarChart
                data={indicator.planFacts || []}
                noCellSize
                id={`chart-${indicator.id}`}
                values={[
                  {
                    title: 'План',
                    key: 'plan',
                  },
                  {
                    title: 'Факт',
                    key: 'fact',
                  },
                ]}
                color={(CHART_MAIN_COLOR, '#32a852')}
                height="350px"
              />
            </ChartCard>
          ))}
        </RowWrapper>
      </LoadingSpinner>
    </div>
  );
}
