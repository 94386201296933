export function getAuthority() {
  const authorityString = localStorage.getItem('KRG_SIT_CENTER.authority');
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }

  return authority || ['GUEST'];
}

export function setAuthority(authority) {
  const userAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('KRG_SIT_CENTER.authority', JSON.stringify(userAuthority));
}
