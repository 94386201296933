import React from 'react';

const WeatherRP5 = () => {
  return (
    <iframe
      src="https://iframe.krgsc.kz/weather-rp5"
      title="weatherRP5"
      style={{ border: 'none' }}
      width="100%"
      height="100%"
    />
  );
};
export default WeatherRP5;
